import React, { useContext } from "react";
import { AppBar, Container, Toolbar, Box, IconButton, Menu, MenuItem, Link, Button, Tooltip } from "@mui/material";
import logoWhite from "../images/dc-logo-white.png";
import logoWhiteMobile from "../images/dc-logo-white-vertical.png";

import {UserContext} from '../Wrapper';
import "./header.scss";
import Icon from "./icon";

const pages = [{text: 'Home', href: '/'}, {text: 'My Goals', href: '/goals'}, {text: 'About the Book', href: '/aboutthebook'}];
const settingsLoggedIn = [{ text: 'My Profile', href: '/profile' }, { text: 'Logout', href: '/logout' }];
const settingsLoggedOut = [{ text: 'Sign In', href: '/signin' }];

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = useContext(UserContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (<>
    <AppBar sx={{background: "linear-gradient(230deg, rgb(38, 67, 142), rgb(26, 122, 191))" }} position="static">
      <Container maxWidth="100vw">
        <Toolbar disableGutters sx={{width: "100%"}}>
          <Link href="/">
            <img src={logoWhite} className="logo" alt="Discovering Christ logo" height={50}/>
          </Link>
          <Link href="/">
            <img src={logoWhiteMobile} className="logoMobile" alt="Discovering Christ logo" height={45}/>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <Icon>menu</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({href, text}) => (
                <MenuItem key={text} onClick={handleCloseNavMenu}>
                  <Link sx={{ color: '#232363' }} underline="none" href={href}>{text}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({text, href}) => (
              <Button
                key={text}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, fontSize: "1.2em", color: 'white', display: 'block' }}
              >
                <Link sx={{color: 'white'}} underline="none" href={href}>{text}</Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Account">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Icon size={32}>account_circle</Icon>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {(user ? settingsLoggedIn : settingsLoggedOut).map(({text, href}) => (
                <MenuItem key={text} onClick={handleCloseUserMenu}>
                  <Link underline="none" href={href}>{text}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </>);
}