import { child, get, getDatabase, ref, update } from "firebase/database";
import { firebaseApp } from "..";

export async function saveUser (user) {
  try {
    const db = getDatabase(firebaseApp);
    const {uid, email, displayName: name, phoneNumber: phone, photoURL} = user;
    console.log(JSON.stringify(user));
    const existingInfo = (await get(child(ref(db), `users/${uid}`)))?.val() || {};
    console.log(`existingInfo = `, JSON.stringify(existingInfo));
    await update(ref(db, `users/${uid}`), {
      email: email || existingInfo?.email, 
      phone: phone || existingInfo?.phone,
      name: existingInfo?.name || name,
      photoURL: photoURL || existingInfo?.photoURL, 
      unsubEmails: false,
      unsubTexts: false,
    });
  }
  catch (err) { 
    alert(`An error occurred while logging in: ${err}`);
  }
};