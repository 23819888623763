import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Button, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Stepper, Step, StepLabel, TextField, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import ReactMarkdown from "react-markdown";
import { getDatabase, ref, push, child, update, remove } from "firebase/database";

import {UserContext} from '../Wrapper';
import { firebaseApp } from '../index.js'
import "./goalStepper.scss";
import Icon from "./icon";
import { masterGoalList } from "./data";

const days = new Array(10).fill().map((_, i) => i + 1);

export default function GoalStepper({onClose, editGoal}) {
  const [pulledData, setPulledData] = useState(false);
  const [userHasEmail, setUserHasEmail] = useState(false);
  const [userHasPhone, setUserHasPhone] = useState(false);
  const [step, setStep] = useState(0);
  const [goalKey, setGoalKey] = useState();
  const [reminderKey, setReminderKey] = useState();
  const [attribute, setAttribute] = useState("");
  const [idea, setIdea] = useState();
  const [goalText, setGoalText] = useState("");
  const [selectedRB, setSelectedRB] = useState(-1);
  const [reminderDays, setReminderDays] = useState(10);
  // const [reminderHour, setReminderHour] = useState(-1);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendText, setSendText] = useState(false);
  const user = useContext(UserContext);

  const closeAddGoalDialog = () => {
    onClose();
  };

  const onAttributeChange = (event) => {
    setAttribute(event.target.value);
    setSelectedRB(-1);
    setStep(1);
  };

  const goalRBSelected = (event) => {
    setSelectedRB(event.target.value);
    setIdea(event.target.name);
    setStep(2);
  };

  const backButtonClick = () => { setStep(step - 1); }

  const nextButtonClick = () => { setStep(step + 1); }

  const saveButtonClick = async () => {
    // Save the goal to the database
    const db = getDatabase(firebaseApp);
    const {userId} = user || {};
    if (!userId) return;

    const gKey = goalKey || push(child(ref(db), `users/${userId}/goals/`)).key, // If this is a new goal, get a new key for it
      newGoal = Boolean(goalKey),
      rKey = !reminderKey && (sendEmail || sendText)
        ? push(child(ref(db), 'reminders/')).key //turned on reminders, get a key
        : reminderKey && !sendEmail && !sendText 
          ? "" //turned off reminders
          : reminderKey,
      newReminder = !reminderKey && (sendEmail || sendText);
      
    if (rKey === "" && reminderKey !== "") {
      remove(child(ref(db), `reminders/${reminderKey}`));
    }

    try {
      await update(ref(db), {
        [`users/${userId}/goals/${gKey}`]: {
          text: goalText,
          attribute: attribute,
          rb: selectedRB,
          ...(rKey ? {reminderKey: rKey} : {}), 
          ...(newGoal ? {createDate: Date.now()} : {}),
        },
        ...(rKey ? {[`reminders/${rKey}`]: {
          days: reminderDays,
          g: gKey,
          m: userId,
          sendEmail: sendEmail,
          sendText: sendText,
          ...(newReminder ? {lastSent: Date.now()} : {}),
        }} : {}),
      });
      closeAddGoalDialog();
    }
    catch (err) {
      console.log(`ERROR saving goal: `, err);
      alert(`Failed to save goal: ${JSON.stringify(err)} Please try again.`);
    }
  };

  useEffect(() => {
    (async () => {
      if (pulledData) return;

      if (editGoal) {
        setStep(2);
        const {key, data: {attribute, text, rb, reminderKey, reminder}} = editGoal;
        const {days, sendEmail, sendText} = reminder || {};
        setGoalKey(key);
        setAttribute(attribute);
        setGoalText(text);
        setSelectedRB(rb);
        setIdea(masterGoalList.goals.filter(g => g.attribute === attribute)[0].ideas[rb]);
        setReminderKey(reminderKey);
        if (reminderKey) {
          setReminderDays(days || 10);
          setSendEmail(sendEmail);
          setSendText(sendText);
        }
        else {
          setSendEmail(false);
          setSendText(false);
        }
      }
      // Get the user's phone and email address
      const {userId, email, phone} = user || {};
      if (!userId) return;
      email ? setUserHasEmail(true) : setSendEmail(false);
      phone ? setUserHasPhone(true) : setSendText(false);

      setPulledData(true);
    })();
    
  }, [editGoal, user, pulledData]);

  return (<div className="grayBackground">
    <Box className="modalDialog">
      <Box sx={{ background: "linear-gradient(230deg, rgb(38, 67, 142), rgb(26, 122, 191))", width: "100%" }}>
        <Typography variant="h6" sx={{color: "white", padding: "15px 15px 15px 20px" }}>
          {editGoal != null ? "Edit my goal" : "Create a new goal"}
        </Typography>
        <IconButton sx={{ position: "absolute", right: "20px", top: "20px" }} onClick={closeAddGoalDialog}>
          <Icon>close</Icon>
        </IconButton>
      </Box>
      <Stepper activeStep={step} orientation="vertical" sx={{padding: "20px", maxWidth: "500px", maxHeight: "85vh", overflow: "scroll"}}>
        <Step>
          <StepLabel>Select an attribute of our Savior Jesus Christ</StepLabel>
          <div>
            <Select sx={{ minWidth: "200px", marginLeft: "33px" }} displayEmpty value={attribute} onChange={onAttributeChange}>
              {masterGoalList.goals.map((goal, i) => 
                <MenuItem key={i} value={goal.attribute}>{goal.attribute}</MenuItem>
              )}
            </Select>
            {step === 0 && editGoal !== null && <Button sx={{ margin: "5px 10px" }} variant="contained" onClick={nextButtonClick}>Next</Button>}
          </div>
        </Step>
        <Step>
          <StepLabel>Select a possible idea to develop this attribute</StepLabel>
          {step === 1 && <>
            <RadioGroup sx={{ margin: "5px 0 0 40px", maxHeight: "50vh", flexWrap: "nowrap", overflow: "scroll", paddingLeft: 1 }} onChange={goalRBSelected} defaultValue={selectedRB !== -1 ? selectedRB?.toString() : null} >
              {masterGoalList.goals.filter(g => g.attribute === attribute)[0]?.ideas.map((idea, index) => 
                <FormControlLabel sx={{ border: "1px solid rgba(100, 100, 100, 0.1)", borderRadius: "6px", marginBottom: "10px"}} 
                                  value={index?.toString()} 
                                  control={<Radio sx={{ padding: "9px 5px 9px 2px" }} />} 
                                  name={idea} 
                                  label={<ReactMarkdown linkTarget="_blank">{idea}</ReactMarkdown>}
                                  key={idea} />
              )}
            </RadioGroup>
            <Button sx={{ margin: "0 5px 0 30px" }} variant="outlined" onClick={backButtonClick}>Back</Button>
            {selectedRB !== -1 && <Button sx={{ margin: "0 5px" }} variant="contained" onClick={nextButtonClick}>Next</Button>}
          </>}
          {step === 2 && <Typography sx={{ padding: "0 10px 0 35px", wordBreak: "break-word" }}>{idea}</Typography>}
        </Step>
        <Step>
          <StepLabel>Create a goal to help you work on this idea</StepLabel>
          {step === 2 && <>
            <TextField multiline sx={{ width: "90%", paddingRight: "20px", margin: "10px 80px 10px 33px" }} maxRows={4} value={goalText} label="My goal" onChange={(event) => setGoalText(event.target.value)}/>
            <Box>
              <Button sx={{ margin: "0 5px 0 33px" }} variant="outlined" onClick={backButtonClick}>Back</Button>
              <Button sx={{ margin: "0 5px" }} variant="contained" disabled={goalText === ''} onClick={nextButtonClick}>Next</Button>
              {goalKey && <Button sx={{ margin: "0 5px" }} variant="contained" disabled={goalText === ''} onClick={saveButtonClick}>Save</Button>}
            </Box>
          </>}
          {step === 3 && <Typography sx={{ padding: "0 10px 0 35px", wordBreak: "break-word", maxHeight: "20vh", overflow: "scroll" }}>{goalText}</Typography>}
        </Step>
        <Step>
          <StepLabel optional={<Typography variant="caption">Optional</Typography>}>Set a reminder so you can remember your goal</StepLabel>
            {step === 3 && <Box sx={{ padding: "0 10px 0 35px" }}>
              <Box sx={{ display: "flex", flexFlow: "column", paddingBottom: "10px"}}>
                <FormControlLabel 
                  disabled={!userHasEmail} 
                  control={<Checkbox checked={Boolean(sendEmail)} onChange={e => setSendEmail(e.target.checked)} />} 
                  label="Send me an email message" 
                />
                {!userHasEmail && <Typography 
                  sx={{ paddingLeft: "30px", color: "#ff6666" }} 
                  variant="caption">
                    To enable this option, save the goal and then go to "My Profile" and add your email address.
                </Typography>}
                <FormControlLabel 
                  disabled={!userHasPhone} 
                  control={<Checkbox checked={Boolean(sendText)} onChange={e => setSendText(e.target.checked)} />} 
                  label="Send me a text message" 
                />
                {!userHasPhone && <Typography 
                  sx={{ paddingLeft: "30px", color: "#ff6666" }} 
                  variant="caption">
                    To enable this option, save the goal and then go to "My Profile" and add your mobile phone number.
                </Typography>}
              </Box>
              {(sendEmail || sendText) && <>Remind me every <Select sx={{marginBottom: 2, marginLeft: 1, marginRight: 1}} value={reminderDays?.toString()} onChange={(event) => setReminderDays(event.target.value)}>
                {days.map(i => (
                  <MenuItem key={i} value={i}>{i}</MenuItem>
                ))}
              </Select> day(s)</>}
              {/* day(s) at 
              <Select sx={{margin: "0 5px"}} value={reminderHour} onChange={(event) => setReminderHour(event.target.value)}>
                {times.map(({value, text}) => (
                  <MenuItem value={value}>{text}</MenuItem>
                ))}
              </Select> */}
              <Box>
                <Button variant="outlined" onClick={backButtonClick}>Back</Button>
                <Button sx={{marginLeft: "10px"}} variant="contained" onClick={saveButtonClick}>Finish</Button>
              </Box>
            </Box>}
        </Step>
      </Stepper>
    </Box>
  </div>);
}