import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Box, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Fab, CircularProgress, Typography, Grid, Snackbar } from '@mui/material';
import { getDatabase, ref, onValue, remove, child, update, get, push } from "firebase/database"

import GoalStepper from '../../components/goalStepper';
import {UserContext} from '../../Wrapper';
import { firebaseApp } from '../../index.js';
import Icon from "../../components/icon";
import newGoalPrompt from "../../images/new-goal-prompt.svg";
import MobileViewGoalList from "./MobileViewGoalsList";
import GoalsList from "./GoalsList";
import "./goals.scss";

export default function Goals({addGoal}) {
  const [params, setPageParams] = useSearchParams();
  const db = getDatabase(firebaseApp);
  const [loading, setLoading] = useState(true);
  const [goalList, setGoalList] = useState([]);
  const [addingGoal, setAddingGoal] = useState(addGoal || false);
  const [editGoal, setEditGoal] = useState();
  const [dialog, setDialog] = useState();
  const [snackbar, setSnackbar] = useState();

  const user = useContext(UserContext);

  useEffect(() => {
    const {userId} = user || {};
    if (!userId) return;

    return onValue(ref(db, `users/${userId}/goals`), async (snapshot) => {
      const goalList = await Promise.all(Object.entries(snapshot?.val() || {})?.map(async ([key, val]) => ({
        key,
        data: {
          ...val,
          reminder: val?.reminderKey && val?.reminderKey !== ""  
            ? (await get(ref(db, `reminders/${val?.reminderKey}`)))?.val()
            : {},
        },
      })));
      setGoalList(goalList);
      setLoading(false);
      const view = params.get("view");
      if (view) {
        setEditGoal({
          key: view,
          data: goalList?.find(({key}) => key === view)?.data,
        });
        setAddingGoal(true);
        setPageParams(params.delete("view"));
      }
      const disableReminders = params.get("disableReminders");
      if (disableReminders) {
        reminderSwitchPressed({
          type: "email", 
          key: disableReminders, 
          data: goalList?.find(({key}) => key === disableReminders)?.data, 
          e: {target: {checked: false}},
        });
        setPageParams(params.delete("disableReminders"));
      }
    });
  }, [user]);

  const reminderSwitchPressed = async ({type, key, data, e}) => {
    const {reminder, reminderKey} = data;
    const existingData = {
      ...data,
      reminder: null,
    };
    const {userId} = user || {};

    const turnedOn = e.target.checked;
    setSnackbar(`${type === "email" ? "Email" : "Text"} reminders turned ${turnedOn ? "on" : "off"}`);
    if (turnedOn) {
      if (!reminderKey) {
        //no reminder created yet
        const rKey = push(child(ref(db), 'reminders/')).key;
        return await update(ref(db), {
          [`users/${userId}/goals/${key}`]: {
            ...existingData,
            reminderKey: rKey,
          },
          [`reminders/${rKey}`]: {
            days: 10, //use a default of 10 days reminder period
            g: key,
            m: userId,
            sendEmail: type === "email",
            sendText: type === "text",
            lastSent: Date.now(),
          },
        });
      }

      //update existing reminder
      return await update(ref(db), {
        [`users/${userId}/goals/${key}`]: {
          ...existingData,
          reminderKey,
          lastModified: Date.now(), //lastModified needed to trigger onValue() to update state
        },
        [`reminders/${reminderKey}`]: {
          ...reminder,
          [type === "email" ? "sendEmail" : "sendText"]: true,
          lastSent: Date.now(),
        },
      });
    }
    if (!turnedOn) {
      if (!reminderKey) return;

      if ((type === "email" && reminder?.sendText) ||
          (type === "text" && reminder?.sendEmail)) {
        //update existing reminder (because one reminder option is still on)
        return await update(ref(db), {
          [`users/${userId}/goals/${key}`]: {
            ...existingData,
            reminderKey,
            lastModified: Date.now(), //lastModified needed to trigger onValue() to update state
          },
          [`reminders/${reminderKey}`]: {
            ...reminder,
            [type === "email" ? "sendEmail" : "sendText"]: false,
            lastSent: Date.now(),
          },
        });
      }

      //delete reminder
      remove(child(ref(db), `reminders/${reminderKey}`));
      return await update(ref(db), {
        [`users/${userId}/goals/${key}`]: {
          ...existingData,
          reminderKey: null,
        },
      });
    }
  };

  return <>
    <Helmet title="My Goals"/>
    <Dialog open={Boolean(dialog)} onClose={() => setDialog()} aria-labelledby="title">
      <DialogTitle id="title">{dialog?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setDialog()}>No</Button>
        <Button color={dialog?.buttonColor} onClick={() => {
          dialog?.onFinish();
          setDialog();
        }}>Yes</Button>
      </DialogActions>
    </Dialog>
    <Snackbar 
      open={Boolean(snackbar)} 
      autoHideDuration={6000}
      onClose={() => setSnackbar()}
      message={snackbar}
    />
    <Box sx={{padding: "20px", width: "calc(100vw - 40px)", minHeight: "87vh", backgroundColor: "#f0f4f8"}}>
      <h1>My Goals</h1>
      {addingGoal && <GoalStepper onClose={() => {
        setAddingGoal(false);
        setEditGoal(null);
      }} editGoal={editGoal}/>}
      {!loading && !addingGoal && goalList?.length === 0 && <img src={newGoalPrompt} alt="Click the + button to add a goal!" style={{position: "absolute", bottom: "95px", right: "45px", minWidth: "270px", maxWidth: "20vmax", opacity: 0.7}}/>}
      {!addingGoal && <Fab color="primary" aria-label="add" sx={{position: "fixed", bottom: 16, right: 16}} onClick={() => setAddingGoal(true)}>
        <Icon>add</Icon>
      </Fab>}
      {loading && <CircularProgress sx={{margin: 4}}/>}
      {!loading && goalList?.length === 0 && <Grid sx={{width: "100%", textAlign: "center", color: "gray"}}>
        <Typography variant="p"><i>No goals...yet!</i></Typography>
      </Grid>}
      <MobileViewGoalList {...{goalList, reminderSwitchPressed, setAddingGoal, setDialog, setEditGoal}}/>
      {!loading && <GoalsList {...{goalList, reminderSwitchPressed, setAddingGoal, setDialog, setEditGoal}}/>}
    </Box>
  </>;
}
