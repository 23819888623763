import React, { useContext, useState } from "react";
import { Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { getDatabase, ref, remove, child, update } from "firebase/database"

import ReminderSwitch from "./ReminderSwitch";
import AttributeChip from "./AttributeChip";
import { CheckButton, DeleteButton, EditButton } from "./ActionButtons";
import { UserContext } from '../../Wrapper';
import { firebaseApp } from '../../index.js';
import Icon from "../../components/icon";

export default function MobileViewGoalList({goalList, reminderSwitchPressed, setAddingGoal, setEditGoal, setDialog}) {
  const db = getDatabase(firebaseApp);

  const user = useContext(UserContext);
  const [expanded, setExpanded] = useState("");

  return <div className="goalsListMobile">{(goalList ?? [])
    .sort((a, b) => a?.data?.completeDate || 0 - b?.data?.completeDate || 0)
    .map(({key, data: {attribute, text, completeDate, reminder, reminderKey}, data}) => <>
      <Accordion key={key} sx={{marginBottom: 1}} expanded={expanded === key} onChange={(_, expanded) => setExpanded(expanded ? key : "")}>
        <AccordionSummary expandIcon={<Icon color={"black"}>expand_more</Icon>}>
          <Stack direction="row" sx={{alignItems: "center"}}>
            <AttributeChip attribute={attribute} mobile/>
            <Typography sx={{fontSize: "16px", color: completeDate ? "gray" : "black"}}>{expanded === key ? text : text.substring(0, Math.min(40, text.length))}{!expanded && text.length > 40 ? "..." : ""}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {completeDate && expanded === key && <Typography sx={{color: "gray"}} variant="p"><i>Completed on {new Date(completeDate).toLocaleDateString()}</i></Typography>}
          {!completeDate && <><Typography>Reminder Settings</Typography>
          <Stack direction="row">
            <ReminderSwitch icon="mail" name="Email" notifTypeAdded={user?.email} notifTypeUnsubbed={user?.unsubEmails} reminderNotifProp={reminder?.sendEmail} onChange={(e) => reminderSwitchPressed({type: "email", key, data, e})}/>
            <ReminderSwitch icon="sms" name="Text" notifTypeAdded={user?.phone} notifTypeUnsubbed={user?.unsubTexts} reminderNotifProp={reminder?.sendText} onChange={(e) => reminderSwitchPressed({type: "text", key, data, e})}/>
          </Stack>
          <hr/></>}
          <Typography>Actions</Typography>
          <Stack direction="row">
            <EditButton completeDate={completeDate} onClick={() => {
              setAddingGoal(true);
              setEditGoal({key, data});
            }}/>
            <DeleteButton onClick={() =>
              setDialog({
                title: "Delete this goal?",
                message: "Are you sure that you want to delete this goal?",
                buttonColor: "error",
                onFinish: () => {
                  const {userId} = user || {};
                  if (reminderKey) {
                    remove(child(ref(db), `reminders/${reminderKey}`));
                  }
                  remove(child(ref(db), `users/${userId}/goals/${key}`));
                }
              })}/>
            <CheckButton completeDate={completeDate} onClick={() => setDialog({
              title: "Mark this goal as complete?",
              message: "Do you want to mark this goal as completed? You will not receive anymore reminders about it.",
              buttonColor: "success",
              onFinish: async () => {
                const {userId} = user || {};
                try {
                  if (reminderKey) {
                    remove(child(ref(db), `reminders/${reminderKey}`));
                  }
                  await update(ref(db, `users/${userId}/goals/${key}`), { 
                    completeDate: Date.now(),
                    reminderKey: null,
                  });
                }
                catch (err) {
                  alert(`Failed to mark goal complete: ${err}`);
                }
              }
            })}/>
          </Stack>
        </AccordionDetails>
      </Accordion>
  </>)}</div>;
}