import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider} from "firebase/auth";
import { Box, Button, CircularProgress, Grid, IconButton, Link, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { child, get, getDatabase, ref, update } from "firebase/database";

import "./signin.scss";
import { firebaseApp } from "..";
import googleIcon from "../images/google.svg";
import facebookIcon from "../images/facebook.svg";
import { saveUser } from "./utils";

export default function Signin({signup}) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [details, setDetails] = React.useState();
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);

  const signInWithGoogle = async () => {
    setLoading("google");
    setError();
    setDetails();
    try {
      const {user} = (await signInWithPopup(auth, new GoogleAuthProvider(firebaseApp))) || {};
      doRedirect(user);
    }
    catch (err) {
      setLoading();
      if (err?.code === "auth/popup-closed-by-user") {
        return;
      }
      setError('Unable to login with Google. Please try again.');
      setDetails(err);
    }
  }

  const signInWithFacebook = async () => {
    setLoading("facebook");
    setError();
    setDetails();
    try {
      const provider = new FacebookAuthProvider(firebaseApp);
      provider.addScope('email');
      const {user} = (await signInWithPopup(auth, provider)) || {};
      doRedirect({
        ...user,
        email: user?.email || user?.providerData[0]?.email,
      });
    }
    catch (err) {
      setLoading();
      if (err?.code === "auth/popup-closed-by-user") {
        return;
      }
      setError('Unable to login with Facebook. Please try again.');
      setDetails(err);
    }
  }

  const signInClick = async () => {
    setLoading("email");
    setError();
    setDetails();
    if (signup) {
      try {
        const {user} = (await createUserWithEmailAndPassword(auth, email, password)) || {};
        return doRedirect(user);
      }
      catch (err) {
        setLoading();
        if (err?.code === "auth/invalid-email") {
          return setError("Please provide a valid email.");
        }
        if (err?.code === "auth/weak-password") {
          return setError("Your password must be 8 characters long, and must contain at least one uppercase letter and one lowercase letter.");
        }
        if (err?.code === "auth/email-already-in-use") {
          return setError("An account with that email already exists. Please sign in instead.");
        }
        setError('Unable to create your account. Please try again.');
        return setDetails(err);
      }
    }
    try {
      const {user} = (await signInWithEmailAndPassword(auth, email, password)) || {};
      return doRedirect(user);
    }
    catch (err) {
      console.log("ERROR: ", err);
      setLoading();
      setError('The provided email and/or password are incorrect. Please try again.');
    }
  }

  const doRedirect = async (user) => {
    try {
      await saveUser(user);

      setLoading();

      const redirect = window.sessionStorage.getItem("loginDestination");
      window.sessionStorage.removeItem("loginDestination");
      navigate(redirect || "/goals");
    }
    catch (err) { 
      alert(`An error occurred while logging in: ${err}`);
    }
  };

  const title = signup ? "Create an account" : "Sign In";
  return <>
    <Helmet title={title} />
    <Grid container>
      <Grid item xs={0}  sm={2} md={3}/>
      <Grid item xs={12} sm={8} md={6}>
        <Paper className="signinPaper">
          <Box className="upperBox">
            <h1>{title}</h1>
            <Tooltip title="Sign in with Google">
              <IconButton sx={{color: "white"}} onClick={signInWithGoogle}>
                {loading === "google" ? <CircularProgress size="1em"/> : <img src={googleIcon} alt="Google logo" height={32}/>}
              </IconButton>
            </Tooltip>
            <Tooltip title="Sign in with Facebook">
              <IconButton sx={{color: "white"}} onClick={signInWithFacebook}>
                {loading === "facebook" ? <CircularProgress size="1em"/> : <img src={facebookIcon} alt="Facebook logo" height={32}/>}
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="mainBox">
            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Typography sx={{ color: "red" }}>{error}</Typography>
            <Typography sx={{ color: "red", fontSize: "small" }}>{details?.code || JSON.stringify(details)}</Typography>
            <Button variant="contained" onClick={signInClick} disabled={Boolean(loading)}>{loading === "email" && <CircularProgress size="1em" sx={{marginRight: 1}}/>}{signup ? "Create Account" : "Sign In"}</Button>
            {!signup && <Typography sx={{padding: "10px 0px"}}>
              <Link underline="none" href="/forgot-password">Forgot your password?</Link>
            </Typography>}
            {!signup && <Typography sx={{padding: "30px 20px 10px 20px"}}>Don't have an account yet? <Link underline="none" href="/signup">Create one here</Link></Typography>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </>;
}