import { Tooltip, Switch, Stack } from '@mui/material';

import Icon from "../../components/icon";


export default function ReminderSwitch({name, notifTypeAdded, notifTypeUnsubbed, reminderNotifProp, icon, onChange}) {
  return <Tooltip title={notifTypeAdded && !notifTypeUnsubbed ? `${name} notifications are ${reminderNotifProp ? "ON" : "OFF"}` : `To enable ${name.toLowerCase()} notifications, please ${notifTypeUnsubbed ? `uncheck "Unsubscribe from all ${name.toLowerCase()}s"` : `add ${name === "Email" ? "an email address" : "a phone number"}`} in your Profile settings.`}>
    <Stack direction="row" sx={{alignItems: "center"}}>
      <Icon color={notifTypeAdded && !notifTypeUnsubbed && reminderNotifProp ? "#1a7abf" : null}>{icon}</Icon>
      <Switch disabled={!notifTypeAdded || notifTypeUnsubbed} checked={Boolean(notifTypeAdded && !notifTypeUnsubbed && reminderNotifProp)} onChange={onChange}/>
    </Stack>
  </Tooltip>;
} 