// * and * for italics
export const masterGoalList = 
{
	"goals": [
		{
			"attribute": "Advocate",
			"title": "I Can Be an Advocate",
			"ideas": [
				"I can be an advocate by looking for good and virtuous qualities in those I know and then speaking favorably of these attributes to others.",
				"No one is perfect, and it is easy to find fault in others. I can avoid this natural response by thinking positively about others so that I do not “accuse” them of their weaknesses, either in my mind or verbally.",
				"When I see others being treated unfairly or rudely, I can speak up in their defense, and advocate fairness and kindness for all.",
				"I can be an advocate for my Savior, defending and upholding Him as I proclaim His goodness to my family and friends."
			]
		},
		{
			"attribute": "Acknowledges Authority",
			"title": "I Can Acknowledge Authority",
			"ideas": [
				"I can avoid giving those outside my stewardship unsolicited advice that might infringe upon their agency and hamper them in their spiritual growth.",
				"I can obey, sustain, and support those who have been called to preside over me.",
				"I can examine my own stewardship and, through the promptings of the Holy Spirit, find those areas in which I can improve my stewardship.",
				"As I preside over the stewardship I have been given, I can use the Holy Spirit as I listen to, counsel with, and love those whom I have been asked to guide."
			]
		},
		{
			"attribute": "Authorized",
			"title": "Through Christ, I Can Act with Authority",
			"ideas": [
				"When I am guided by the Holy Spirit, I can say and do those things that the Savior wants me to say and do.",
				"I can live my life seeking after righteousness and always remembering my Savior so that I may have the Holy Spirit to guide me.",
				"I can check myself before speaking to others, to verify that I am being led by the Spirit ([see D&C 6:36](https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/6?lang=eng&id=p36#p36)), and not merely speaking with my own opinions."
			]
		},
		{
			"attribute": "Balanced",
			"title": "I Can Balance My Life with Correct Priorities",
			"ideas": [
				"I can make God the balanced center of my life by beginning my day with prayer, and focusing on doing those things He has asked me to do.",
				"To achieve balance in my life, I can constantly remember my Savior throughout the week, and not just on Sunday, thereby obeying the sacramental covenant to “always remember him” ([D&C 20:77, 79](https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20?lang=eng&id=p77,79#p77,79)).",
				"I can turn to my Savior and rely upon Him, in sunshine and in rain, in good times and in bad times.",
				"I can begin each day by taking the time to meditate and connect with God. By doing this, I can feel the Holy Spirit and receive His direction before the rush of the day descends upon me."
			]
		},
		{
			"attribute": "Charitable",
			"title": "I Can Be Charitable",
			"ideas": [
				"I can open my eyes to the needs of those around me, filling my life with service to others.",
				"Through small and simple acts of service, I can bless the lives of others.",
				"I can donate generously to charitable causes, especially to the fast offering fund of the Church.",
				"I can treat others with kindness and love, viewing everyone as children of our Heavenly Father."
			]
		},
		{
			"attribute": "Comforter",
			"title": "I Can Comfort and Console",
			"ideas": [
				"Like my Savior, I can speak reassuring words of comfort and peace to those who are troubled and weighed down by sorrow.",
				"By being kind and considerate to others, I can avoid adding to the burdens that they carry.",
				"I can open my eyes and notice those around me who could use a comforting word or a listening ear, helping to lighten their burdens.",
				"Each day I can ask my Father, “Whom can I comfort and bless today?”"
			]
		},
		{
			"attribute": "Committed",
			"title": "I Can Be Committed",
			"ideas": [
				"Even though the adversities of life may give me challenges, I can choose to remain true to my Savior, to avoid bitterness, and to stay away from “forbidden paths” (see [1 Nephi 8:28](https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/8?lang=eng&id=p28#p28)).",
				"I can encourage others to remain true and committed to our Father’s plan of salvation.",
				"I can show my commitment by making and *keeping* sacred covenants with my Savior—including baptism and temple covenants—and by renewing these covenants with regular temple and Church attendance.",
				"I can apply my heart to living the gospel fully—with wholehearted commitment and without seeking for excuses or self-justification."
			]
		},
		{
			"attribute": "Compassionate",
			"title": "I Can Be Compassionate",
			"ideas": [
				"I can open my eyes, using compassion and mercy, to notice those around me who might be hurting, struggling, or experiencing difficulty.",
				"I can improve someone’s day or save someone’s life with a kind word, a simple smile, or a heartfelt conversation.",
				"I can withhold judgment, looking at everyone with mercy and compassion.",
				"I can defend those who have been falsely accused by asking others to have compassion for them."
			]
		},
		{
			"attribute": "Concerned",
			"title": "I Can Feel Concern for Others",
			"ideas": [
				"When I hear of the problems or trials that others are going through, I can include them in my prayers, asking Heavenly Father to help and bless them.",
				"I can show concern for the eternal welfare of my family and friends by encouraging them, and by strengthening their faith in God through sharing my witness and testimony with them.",
				"I can put my digital distractions aside and notice those around me.",
				"I can take the time to pause and talk with others, discovering the deep questions that they have, and learning of the soul-searing trials they are experiencing. By so doing, I can “mourn with those that mourn; yea, and comfort those that stand in need of comfort” (Mosiah 18:9)."
			]
		},
		{
			"attribute": "Consistent",
			"title": "I Can Be a Source of Consistency",
			"ideas": [
				"I can remain true to my faith, true to my testimony, and true to the gospel of Jesus Christ.",
				"I can strive to become a person upon whom others can rely.",
				"I can keep my word and fulfill my promises so that I am a source of consistency.",
				"I can be measured and careful in what I do and say so that I don’t make rash promises but, instead, take a consistent approach to life."
			]
		},
		{
			"attribute": "Content",
			"title": "I Can Be Content",
			"ideas": [
				"Since unrealistic expectations can lead to heartbreak and sorrow, I will try to set my expectations according to what the Lord desires for me.",
				"I can look heavenward for God’s approval, rather than relying upon the finicky and changing opinions of other mortals.",
				"I can find joy in the present—here and now. The past is behind me. The future will come. But, I am here in the present moment of today, finding contentment and happiness in the life I have been given.",
				"I can avoid comparing myself to others."
			]
		},
		{
			"attribute": "Counselor",
			"title": "I Can Be a Righteous Counselor",
			"ideas": [
				"By living close to the Holy Spirit and receiving God’s counsel for me and my stewardship, I can give counsel to those whom I love in a spirit of charity and patience, using heavenly wisdom.",
				"I can seek the counsel of my priesthood leaders to help me.",
				"I can avoid dispensing my own opinions as counsel to others."
			]
		},
		{
			"attribute": "Covenant Keeper",
			"title": "I Can Be a Covenant Keeper",
			"ideas": [
				"I can live up to the covenants I have made with my Savior, obeying Him and doing those things I have covenanted to do.",
				"Like my Savior, I can also keep my covenants with others, fulfilling the agreements and promises I have made with them.",
				"If I find it impossible to fulfill my agreements with others, I can quickly inform them of the difficulties I am having. I can then try to make everything right to the best of my ability."
			]
		},
		{
			"attribute": "Covenant Maker",
			"title": "I Can Make and Keep Covenants",
			"ideas": [
				"Throughout my life, I can renew my baptismal covenants by partaking of the sacrament with purpose and intent.",
				"I can strive to make and keep all of the covenants that the Savior has in store for me, including baptismal, priesthood, and temple covenants.",
				"Each day I can prepare to partake of the sacrament on the upcoming Sunday by seeking to live a more holy life, and by repenting quickly of those things that I do wrong."
			]
		},
		{
			"attribute": "Creator",
			"title": "I Can Be a Creator",
			"ideas": [
				"I can improve this world and add positivity to it by using my creative abilities to uplift others and enlighten all of humanity.",
				"My Savior creates, but the devil destroys. Instead of destroying others through criticism, I can choose to follow my Savior by encouraging my family and friends and building up their self-worth.",
				"I can use my powers of creativity to create many wonderful things— things that will testify of my Creator and His marvelous gifts to me."
			]
		},
		{
			"attribute": "Criticized",
			"title": "I Can Be Valiant When Criticized",
			"ideas": [
				"Like my Savior, I can be strengthened by the Holy Spirit and follow His direction when I am criticized or accused.",
				"Since I am not perfect and the criticism that I receive might be true, I will let the Spirit guide me in how I react to the criticism that I receive.",
				"I will reach out in a spirit of love to those who criticize me, extending charity to all of my brothers and sisters."
			]
		},
		{
			"attribute": "Denouncer of Deceit",
			"title": "I Can Avoid Hypocrisy and Self-Deceit",
			"ideas": [
				"I can avoid hypocrisy by acting in accordance with the things I teach others. I can do as I say.",
				"I can avoid self-deceit by humbling myself before God, and by following His will with all my heart, mind, and strength.",
				"I can seek the glory of God in all that I do, and not seek for the praise of others."
			]
		},
		{
			"attribute": "Defender",
			"title": "I Can Defend Others and Help the Helpless",
			"ideas": [
				"I can stand up and defend those who are being persecuted or mocked.",
				"I can open my eyes to the needs of those around me, and help the helpless as my Savior would.",
				"I can look for the good in people so that I can avoid mocking or persecuting others."
			]
		},
		{
			"attribute": "Deliverer",
			"title": "I Can Be a Deliverer",
			"ideas": [
				"I can look for opportunities in my life to deliver those who are experiencing difficulties and might need my help.",
				"I can help to deliver others from the emotional or mental burdens they are carrying by offering a listening ear and an understanding heart.",
				"When people petition me for help, I can readily respond. As I follow the guidance of the Holy Spirit, I will know what things I can do to help them.",
				"I can deliver those who have died without a knowledge of the gospel— who are helpless in spirit prison—by seeking their names in my family tree, and by going to the temple to make covenants on their behalf so that they can receive the blessings of the gospel in the spirit world."
			]
		},
		{
			"attribute": "Dependable",
			"title": "I Can Be Dependable",
			"ideas": [
				"I can keep those promises I have made to the Lord by faithfully fulfilling any callings or assignments that I have been given.",
				"Through daily prayer, scripture study, weekly Church attendance, monthly fasting, and regular temple attendance, I can keep my promises to be a loyal follower of my Savior.",
				"I can be dependable by remaining true to my word and by fulfilling the promises I have made to my brothers and sisters in mortality."
			]
		},
		{
			"attribute": "Diligent",
			"title": "I Can Be Diligent",
			"ideas": [
				"I can diligently follow my Savior by keeping my covenants, staying in touch with heaven through daily prayer, and holding on to the iron rod through daily scripture study.",
				"I can be diligent in the Church callings that I receive throughout my life, fulfilling these callings to the best of my ability.",
				"I can be diligent in keeping the commandments by immediately following the counsel of living prophets and apostles in these spiritually-perilous latter days."
			]
		},
		{
			"attribute": "Direct",
			"title": "I Can Be Direct and Forthright",
			"ideas": [
				"I can be the type of person upon whom others can always rely.",
				"I can remove any guile or ulterior motives from my life so that I can be direct and forthright with everyone.",
				"I can fulfill the promises that I have made to others.",
				"I can seek to be forthright and honest in all that I say and do."
			]
		},
		{
			"attribute": "Discerner",
			"title": "I Can Be a Discerner",
			"ideas": [
				"I can pray to have the Holy Spirit teach me through the gift of discernment so that I can understand the true nature of things.",
				"Instead of rashly judging others, I can withhold my judgment and seek for the gift of discernment to learn what is truly happening in their lives.",
				"I can live close to the Holy Spirit so that I can enjoy His wisdom and thus discern for myself how God truly sees me—as His beloved child."
			]
		},
		{
			"attribute": "Doer",
			"title": "I Can Be a Doer of Good Works",
			"ideas": [
				"I can serve others out of pure love, free of any other motives.",
				"I can look for the face of my Savior in those I serve.",
				"I can open my eyes to the needs of those around me, and to those who could use my kind words and service.",
				"I can seek for the will of the Lord in my life, and as I learn what He wants me to do, I can move forward in doing His will."
			]
		},
		{
			"attribute": "Encourager",
			"title": "I Can Encourage Others",
			"ideas": [
				"I can find ways to encourage others in their righteous pursuits.",
				"I can avoid discouraging others through pessimism or other negative talk.",
				"Instead of harboring jealousy and envy of others, I can encourage others to soar and reach even higher heights than I could obtain, so that they can reach their full potential here in mortality."
			]
		},
		{
			"attribute": "Exists",
			"title": "I Exist and I Am Blessed to Be Alive",
			"ideas": [
				"I can find great joy and express my sincere gratitude in knowing that I have been blessed to come to the earth at this time.",
				"I have been blessed with an amazing miracle—my body—which is a sacred gift from God. I can show Him my gratitude by taking care of it.",
				"I will exist forever. I know that I existed before this life as a spirit child of Heavenly Parents. I know that I will exist after my death as I go to the spirit world to await my resurrected body."
			]
		},
		{
			"attribute": "Exonerator",
			"title": "I Can Free Myself and Help Others to Be Free",
			"ideas": [
				"By turning to my merciful Savior and letting go of my bad behaviors, I can free myself of the terrible burden of sin.",
				"I can avoid manipulating others so that they are free to choose.",
				"I can help others to find freedom from sin by encouraging them to turn to Jesus Christ, to repent, and to call on Him for help."
			]
		},
		{
			"attribute": "Fair",
			"title": "I Can Be Fair and Just",
			"ideas": [
				"I can strive to be honest, fair, and just with everyone I know.",
				"When someone is being abused, bullied, or picked on, I can speak up and stop the injustice so that the person can be treated fairly.",
				"Knowing that God is fair and just, I can fill my soul with more faith, confidence, and trust in Him.",
				"I can patiently rely upon the fairness and justice of God to someday reward everyone according to our true intentions."
			]
		},
		{
			"attribute": "Faithful",
			"title": "I Can Be Filled with Faith",
			"ideas": [
				"By focusing on my faith instead of my fears, I can move forward in my life, trusting in my Savior.",
				"Through scripture study, personal prayer, and listening to modern prophets and the Holy Spirit, I can maintain my faith no matter how thick the mists of darkness and deception might be in this fallen world.",
				"With my Savior as my bedrock, I can find the faith to help me take the next step forward, no matter how dark the night may seem.",
				"As my faith grows, I can strengthen my family and friends by sharing my faith with them."
			]
		},
		{
			"attribute": "Follower",
			"title": "I Can Be a Loyal Follower",
			"ideas": [
				"I can follow my Savior by humbly obeying Him.",
				"I can seek to learn the Lord’s will for me and then fulfill these heavenly requests to the best of my ability.",
				"Instead of seeking for the approval of others, I can seek for the approval of heaven.",
				"I can resist the temptation to compare myself to others.",
				"Even though I may not receive any recognition, I can be a loyal follower of my Savior to the end."
			]
		},
		{
			"attribute": "Forgiving / Forgetful",
			"title": "I Can Forgive Others",
			"ideas": [
				"By letting go of any grudges that I might be holding within my heart or mind, I can forgive others. This will free me of the burdens I am carrying by holding onto these grudges.",
				"By looking for the good in others—even in my enemies—I can open up my heart to forgive those who have offended me.",
				"I can pray for heaven’s help and strength so that I can forgive others.",
				"Instead of keeping a mental list of those who have offended me, I can *forget* their offenses and instead look for the good in everyone.",
				"I can practice speaking kindly to myself, avoiding any self-criticism."
			]
		},
		{
			"attribute": "Friendly",
			"title": "I Can Be a Friend to All",
			"ideas": [
				"I can look for the best in others, reaching out to everyone as a friend.",
				"I can open my heart and mind so that I can see others in the best light.",
				"I can befriend and treat kindly those who might be rude or inconsiderate, or who might persecute and belittle me.",
				"I can pray for those who are unkind to me, asking God to open their hearts with His love."
			]
		},
		{
			"attribute": "Fulfiller",
			"title": "I Can Fulfill Righteous Desires",
			"ideas": [
				"I can help my Savior fulfill His plans for me by staying on the gospel path and by staying close to Him through continual prayer and scripture study.",
				"I can help my family and friends fulfill their righteous desires. Together we can all improve ourselves and help make this world become a better, Millennial place.",
				"Through prayer, I can verify with the Lord that my deepest desires are righteous and that they are what would be best for me."
			]
		},
		{
			"attribute": "Gatherer",
			"title": "I Can Be a Gatherer",
			"ideas": [
				"I can do my part to gather scattered Israel in the latter days by sharing the glorious message of the restored gospel with others.",
				"I can be a gatherer by sowing seeds of unity and love in my family and in my ward, branch, or stake.",
				"I can help those who have joined the Church to feel accepted, loved, and needed.",
				"I can be a gatherer by healing any feelings of division that might arise within my family, ward, branch, or stake.",
				"I can strengthen others’ testimonies by sharing my faith, friendship, and love, and by helping them as we all gather together as a people of the Lord.",
				"I can gather the names of my ancestors and go to the temple to do the ordinances of salvation for them."
			]
		},
		{
			"attribute": "Good",
			"title": "I Can Be a Source of Goodness",
			"ideas": [
				"I can be a conduit of light to others as the purity, hope, and joy found in Jesus Christ shines through me.",
				"Through my righteous example and kind words, I can bring goodness and light into this world.",
				"As I become more like my Savior, I can reflect His light, which will spread goodness into the world.",
				"I can seek for the good in this world and express my heartfelt gratitude to my Savior for all of it."
			]
		},
		{
			"attribute": "Full of Grace",
			"title": "I Can Be Filled with Grace",
			"ideas": [
				"Like my Savior, I can treat others with mercy and love, extending grace unto others in spite of their imperfections and weaknesses.",
				"I can look beyond others’ failures and foibles to see that we are all children of God, trying our best with the light we have each been given.",
				"As I fill my heart with kind thoughts towards others, I know that my Savior will extend His grace unto me, filling my life with tender mercies."
			]
		},
		{
			"attribute": "Grateful",
			"title": "I Can Be Grateful and Thankful",
			"ideas": [
				"By looking each day for those things for which I can be grateful, I can develop a humble and thankful heart and attitude.",
				"I can express my gratitude for even the simplest things.",
				"I am living during one of the most blessed times in human history. I have so much for which I can thank my Father and my Savior.",
				"I have been blessed with my body, which is a living miracle. I can receive direct help from the powers of heaven through the Holy Spirit. I have been given so much knowledge through the scriptures. The miracles of this modern age have come about through the inspiration of heaven helping mankind to learn, discover, and grow. I can express my thanks that I have been *so richly* blessed!"
			]
		},
		{
			"attribute": "Guardian of Innocence",
			"title": "I Can Help Defend and Protect Children",
			"ideas": [
				"I can show kindness and consideration to the children around me.",
				"I can defend, protect, and shield innocent children from the evil influences which are so prevalent in this wicked world.",
				"I can try to see the world through a child’s eyes as I seek to understand their concerns and worries. Through this understanding, I can then comfort them, calming their fears and anxieties.",
				"I can be more child-like and trusting in my Father in Heaven and in my Savior, following Them with humble faith and relying upon Their wisdom in those trials that experience in my life."
			]
		},
		{
			"attribute": "Healer",
			"title": "I Can Be a Source of Healing",
			"ideas": [
				"I can be a source of healing to those around me by seeking for any who are hurting emotionally or spiritually, and by doing what I can to help them heal as I listen to them and reassure them that peace can be found in our Savior.",
				"Instead of spreading injury and hurt with my words and attitude, I can choose to have a positive attitude that will heal and uplift others. With patience, and by using kind and gentle words, I can make the world a better place.",
				"I can love everyone whom I meet, helping them to feel our Savior’s love. His love is the true Source of healing."
			]
		},
		{
			"attribute": "Helper",
			"title": "I Can Help Others",
			"ideas": [
				"Life gives me many moments in which I can help others. I can seek for these opportunities to serve my brothers and sisters as I open my eyes to the needs of those around me.",
				"I can seek for the Lord’s help in my life by following Him and obeying His commandments.",
				"I can help my family and friends, whether it be physically, emotionally, or spiritually.",
				"By following the promptings of the Holy Spirit, I can know the best and most inspired way to help my family and friends."
			]
		},
		{
			"attribute": "Holy",
			"title": "I Can Be Holy",
			"ideas": [
				"I can become more holy each day as I eliminate worldly things from my life.",
				"Through the Holy Spirit’s influence and guidance, I can become more dedicated and sanctified in my stewardship, my prayers, and my scripture study.",
				"I can purify my thoughts and thereby be more clean in those things that I say and do.",
				"Through my example I can encourage others to live holy lives—to find peace, joy, and clarity in holy living.",
				"I can add holiness to my life by attending the sacred temple and by worshiping my Savior there."
			]
		},
		{
			"attribute": "Humble",
			"title": "I Can Be Humble and Meek",
			"ideas": [
				"I can follow my Savior by humbly putting Him first in my life.",
				"Since pride is defined as enmity or hatred towards God and man, I can seek the opposite of this by increasing my love towards everyone and by drawing closer in love to my Father and my Savior.",
				"I can choose to be humble by repenting of my mistakes and by forgiving others of their misdeeds.",
				"I can humbly serve others and thereby help to serve my God."
			]
		},
		{
			"attribute": "Inclusive",
			"title": "I Can Be Inclusive",
			"ideas": [
				"I can reach out to everyone in a spirit of love and friendship so that they can also feel the warmth of our Savior’s love in their lives.",
				"By praying for the gift of charity, I can see others as my Savior sees them, and I can extend to them a warm hand of friendship and love.",
				"I can stop any feelings of prejudice that might arise in my heart, and I can learn to love and accept everyone, no matter their race, religion, or background. Every person is a child of God."
			]
		},
		{
			"attribute": "Jealous",
			"title": "I Can Be Fervent and Zealous",
			"ideas": [
				"As I grow in love for my Savior, I can become more fervent and zealous in following and loving Him.",
				"I can zealously protect the character of my Savior by defending Him and by speaking respectively about Him.",
				"I can re-focus any feelings of jealousy that I might feel towards others and zealously redirect my thoughts to remember those things that my Savior has asked me to do and remember the blessings He has already given me."
			]
		},
		{
			"attribute": "Joyful",
			"title": "I Can Feel Joy",
			"ideas": [
				"I can remember my Savior throughout the week so that I will feel the Holy Spirit, which will bring peace and joy to my soul.",
				"I can choose to let the truths of the gospel bring me joy. My Savior has promised me that “if thou shalt ask . . . thou mayest know the mysteries and peaceable things—that which bringeth joy, that which bringeth life eternal” ([D&C 42:61](https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/42?lang=eng&id=p61#p61)).",
				"By trying to be more holy and pure, I can feel the love and joy that comes from my Savior.",
				"I can seek for the peaceful reassurance and joy that comes from the Holy Spirit, basking in the warmth of His sacred presence."
			]
		},
		{
			"attribute": "Judge",
			"title": "I Can Judge Righteously",
			"ideas": [
				"By living close to the Spirit, I can be guided in my judgment of others.",
				"When I hear people judging a person harshly, I can plead on behalf of that person, asking them to look upon that person instead with a feeling of charity and forgiveness.",
				"Life is sometimes hard, and I can withhold my judgment of others because I do not know all of the facts about their life circumstances.",
				"I can avoid gossiping and the spreading of condemnation and judgment of others.",
				"I can forgive others so that I will also be forgiven."
			]
		},
		{
			"attribute": "Knowledgeable",
			"title": "I Can Be Filled with Knowledge and Truth",
			"ideas": [
				"As I rely upon my Savior and seek for the truth to be found in Him, my own knowledge can expand and grow.",
				"I can use the Holy Spirit to discern whether I am receiving incorrect information from sources of half-truths and lies, or whether I am obtaining true knowledge from genuine sources of truth.",
				"I can ask and seek for the Lord’s counsel and wisdom instead of relying solely upon my limited knowledge.",
				"I can seek to increase my knowledge throughout my lifetime as I study and learn from honest sources of truth."
			]
		},
		{
			"attribute": "Leader",
			"title": "I Can Be a Righteous Leader and Steward",
			"ideas": [
				"I can follow and obey my leaders in heaven—my Father and my Savior—and do those things that They have asked me to do.",
				"When I am asked to lead, I can avoid using unrighteous dominion or force. Instead, I can lead with humility, kindness, love, and respect.",
				"I can sustain and support my leaders with an open and willing heart, filled with love.",
				"I can watch over my stewardship by taking care of those blessings I have been given by God."
			]
		},
		{
			"attribute": "Learner",
			"title": "I Can Be a Learner",
			"ideas": [
				"I can continually seek for knowledge and wisdom, using the Holy Spirit to help me discern between truth and error.",
				"I can pursue an education, learning those things that I need to know, which will fill my soul with light.",
				"Instead of wasting time with frivolous things, I can seek for learning about things of eternal significance.",
				"I can make learning a life-long pursuit, knowing that “if a person gains more knowledge and intelligence in this life through his diligence and obedience than another, he will have so much the advantage in the world to come” ([D&C 130:19](https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/130?lang=eng&id=p19#p19))."
			]
		},
		{
			"attribute": "Life-giver",
			"title": "I Can Give Life and Hope to Others",
			"ideas": [
				"Like my Savior, I can be a positive, life-changing influence on my family and friends.",
				"I can introduce others to the gospel so that they can find true meaning and hope in their lives today, and eternal joy in an everlasting life with Heavenly Father and His Son someday in the glorious future.",
				"I can help and strengthen those around me who may have wandered from the true path, so that they can find lives of peace and happiness."
			]
		},
		{
			"attribute": "Light-giver",
			"title": "I Can Be a Source of Light",
			"ideas": [
				"By reflecting my Savior’s light in my life, I can be a shining beacon, bringing happiness and joy to my family and friends.",
				"By faithfully obeying God’s commandments and remaining true to Him I can show my gratitude for the magnificent light He has given me.",
				"Through my actions, I can show others the light and joy of the gospel in my life.",
				"I can continually seek for sources of light and truth in my life."
			]
		},
		{
			"attribute": "Loving",
			"title": "I Can Love Everyone",
			"ideas": [
				"By praying for the gift of charity, I can see others as my Savior sees them. I can have eyes filled with love and with an appreciation for each person’s eternal potential.",
				"I can cultivate and increase my love for others by treating everyone with patience and kindness.",
				"I can forgive myself of those errors that I have made so that I can love myself. This will free me so that I can love others in the same manner in which I have grown to forgive and appreciate myself."
			]
		},
		{
			"attribute": "Loyal",
			"title": "I Can Be Loyal to My Father in Heave",
			"ideas": [
				"I can be loyal to my Heavenly Father and my Savior Jesus Christ by obeying Their commandments.",
				"I can loyally testify to others of the greatness of my God and the many wonderful things He has done for me.",
				"I can eagerly spread the message of the gospel to those whom I know so that they can also add their loyalty and praises to God as they follow Him."
			]
		},
		{
			"attribute": "Magnanimous",
			"title": "I Can Be Magnanimous, Generous, and Kind",
			"ideas": [
				"I can treat my brothers and sisters with kindness and respect.",
				"By paying a generous fast offering, I can help the poor and those who are suffering.",
				"I can be generous with my time and love by empathetically listening to the worries and concerns of my family and friends.",
				"I can be kind and patient with those who might be unkind to me.",
				"In gratitude for my many blessings, I can be generous to God by paying an honest tithing, and by dedicating myself wholeheartedly to His service."
			]
		},
		{
			"attribute": "Martyr",
			"title": "I Can Sacrifice of Myself for Others",
			"ideas": [
				"I can sacrifice my time to serve others as my Savior would.",
				"I can sacrifice my money by paying my tithing, giving generous fast offerings, and contributing to other noble causes.",
				"I can sacrifice my time, my gifts, and my God-given talents by sharing these things with others.",
				"I can sacrifice my prestige, pride, and honor by humbly following my Savior and by testifying of Him to others."
			]
		},
		{
			"attribute": "Mediator",
			"title": "I Can Be a Mediator",
			"ideas": [
				"By following the Holy Spirit, I can find those opportunities in which I can mediate on behalf of those who are being belittled or oppressed.",
				"I can look for the positive qualities in the people whom I meet so that I can defend them and act as their mediator, if needed.",
				"Everything that happens is first created in the mind. So, I can begin the process of mediation towards others by trying to think kindly of everyone, as I try to see the world through their eyes."
			]
		},
		{
			"attribute": "Meditator",
			"title": "I Can Meditate and Ponder",
			"ideas": [
				"When life becomes stressful and complicated, I can slow my breathing by taking long, deep breaths, and turn to my Savior to ask for His assistance.",
				"I can set aside time each day to read the scriptures and to ponder on the word of God.",
				"As I stay close to the Lord throughout my week, I will be able to feel the Holy Spirit and know His will ([see D&C 20:79](https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20?lang=eng&id=p79#p79)).",
				"I can double check that my digital devices are tools that I use when needed—not addictions or distractions that I must use all the time."
			]
		},
		{
			"attribute": "Merciful",
			"title": "I Can Be Merciful",
			"ideas": [
				"Like my Savior, I can fill my heart with love and mercy so that I can be kind, merciful, and thoughtful to everyone whom I meet.",
				"Instead of trying to get ahead of others as if life were a competition, I can look for opportunities to be friendly and merciful to everyone.",
				"By remembering my Savior’s mercy towards me, I can seek to be more merciful to my family and friends.",
				"I can avoid the plague of pride by seeking to be more humble and merciful to everyone, treating them as I would like to be treated."
			]
		},
		{
			"attribute": "Minister",
			"title": "I Can Minister to Others",
			"ideas": [
				"I can physically minister to others by helping them with their struggles and needs.",
				"I can spiritually minister to all by sharing my testimony as well as the light I have received through my Savior, the scriptures, and His prophets.",
				"I can emotionally and spiritually minister to those around me by offering a listening ear, and by offering my support and kindness."
			]
		},
		{
			"attribute": "Miracle-Worker",
			"title": "Through My Savior I Can Bring about Miracles",
			"ideas": [
				"Through my faith and prayers—pleading for God’s mercy and grace—I can bring about miracles in the lives of those whom I know and love.",
				"As I minister to others, I can bring the light and love of my Savior into the lives of others. His influence can help to heal them of their burdens.",
				"I can remember that miracles come through the grace and mercy of Jesus Christ, and they depend on His timing. An unfulfilled request for a miraculous healing does not mean that He is not listening. I know that He knows what is best—and *when* it is best—and I will continue to have faith in His plans for me and for my loved ones"
			]
		},
		{
			"attribute": "Missionary",
			"title": "I Can Be a Missionary",
			"ideas": [
				"I can share with others the good things I experience through the gospel.",
				"I can set an excellent example of having my Savior’s love and light in my life, so that others will want to find this same joy in their own lives.",
				"I can pray for missionary opportunities so that I am led to those who have been prepared by the Lord to hear His gospel message.",
				"I can support the missionary effort through my time and contributions to the Church.",
				"I can aid the missionary work on the other side of the veil by searching for my ancestors and by doing their temple work.",
				"I can open my mouth in a spirit of friendship and love and speak about the gospel with those around me."
			]
		},
		{
			"attribute": "Musical",
			"title": "I Can Enjoy the Power of Good Music",
			"ideas": [
				"By listening to music that brings the Holy Spirit, I can draw closer to my Savior.",
				"I can encourage others to listen to good music by recommending uplifting music that I have discovered.",
				"I can seek for music that is inspiring and holy, using the gift of discernment to avoid music that is not good for my soul."
			]
		},
		{
			"attribute": "Obedient",
			"title": "I Can Be More Obedient",
			"ideas": [
				"Like my Savior, I can seek the direction of heaven before I act.",
				"I can strive to remember that obedience will bring me blessings.",
				"I can seek for true obedience by praying to know my Father’s will and by then obeying Him quickly. ",
				"I can seek to more fully understand and follow the commandments.",
				"As an imperfect child of God, I can repent of my mistakes as I learn to be more obedient.",
				"Even though I am incapable of being perfectly obedient in *all* things right now, I can counsel with the Holy Spirit about specific things which I *can* improve—so that I can become more obedient every day."
			]
		},
		{
			"attribute": "Orderly",
			"title": "I Can Act with Order and Purpose",
			"ideas": [
				"I can use my patriarchal blessing to organize and put in order my lifetime goals.",
				"Through the Holy Spirit’s guidance, I can arrange my day so that I put my Savior first, as I work on those things that will be best for my soul.",
				"By realizing that surprises and unexpected events will arise during my day, I can handle these things with gracefulness and in a spirit of joy. In doing so, I can take the time that is needed to help others.",
				"By putting my Savior first, I can move forward with purpose, with order, and with an understanding of what matters most in mortality."
			]
		},
		{
			"attribute": "Patient",
			"title": "I Can Be Patient",
			"ideas": [
				"I can practice increasing my patience whenever I am required to wait.",
				"I can patiently hold my tongue when I feel like censuring or criticizing someone.",
				"By setting reasonable expectations in my life, I can avoid frustration and disappointment, which often lead to impatience and anger.",
				"I can seek for a spirit of calmness so that I can be more patient with others and with myself.",
				"By holding fast to my faith in my Savior, I can rely upon Him and patiently wait for His timing to be fulfilled."
			]
		},
		{
			"attribute": "Peaceful",
			"title": "I Can Be Peaceful",
			"ideas": [
				"Through following my Savior, I can feel peace in my life—no matter what might be happening around me—because of the promises He has given me. I know that I will be abundantly rewarded in the next life for my righteous choices here.",
				"I can help others to feel the peace of my Savior by spreading the joy and calmness that I feel in Him through my words, and by my kind and thoughtful acts.",
				"When I face trials and struggles, I know that my Savior will be there to support me, and I can rely upon Him as my one, true Source of peace and calm."
			]
		},
		{
			"attribute": "Peacemaker",
			"title": "I Can Be a Peacemaker",
			"ideas": [
				"By filling my soul with charity, I can see others as God sees them—as His children—and treat them peacefully, with kindness and love.",
				"I can be a peacemaker with myself by letting go of my past mistakes.",
				"When conflicts arise, instead of agitating the problem, I can be a “balm of Gilead” (Jeremiah 8:22) that calms the troubled waters around me. I can seek to be a source of peace among my friends and enemies.",
				"When facing a problem or difficult situation, I can seek for my Savior’s guidance so that I will know the “way of peace” (Luke 1:79) and find the best remedy for the problem."
			]
		},
		{
			"attribute": "Perceptive",
			"title": "I Can Be Perceptive and Observant",
			"ideas": [
				"As I draw closer to the inspiration of heaven, I can become more perceptive of the needs of those around me.",
				"I can pray for the gift of perception, and the Holy Spirit can help me to see things as they really are.",
				"I can open my eyes to see the earth as God sees it—a world filled with His children who have their own personal struggles. I can reach out to these children of God in a spirit of love and compassion, as my Savior does.",
				"I can pause in my life and seek to understand God’s will for me as I try to perceive my life through *His* eyes."
			]
		},
		{
			"attribute": "Perfect",
			"title": "I Can Seek for Perfection",
			"ideas": [
				"I may not be perfect yet, but I can continue to improve each day by acting on the promptings I receive—trying to do better and to be kinder.",
				"I know that perfection is pending in mortality, so I will have patience with myself as I learn to do better. I can quickly repent of my mistakes.",
				"I can be perfectly obedient in certain parts of the gospel in which I am already consistent (for example: attending my church meetings, praying daily, paying my tithing, etc.). Through my Savior’s help, I can add to this list, one by one, of those things at which I am perfectly obedient."
			]
		},
		{
			"attribute": "Persecuted",
			"title": "I Can Be Strong in Persecution",
			"ideas": [
				"When persecution comes, I can turn to my Savior and find strength in Him.",
				"I can use my faith in my Savior to bravely face those who might criticize or taunt me, and I can respond to them in a spirit of love and kindness.",
				"I can pray for those who persecute me, that their hearts can be softened so that they can learn the truth about who I really am—a child of God who is trying to follow our Father in Heaven and His Son."
			]
		},
		{
			"attribute": "Planner",
			"title": "I Can Be a Planner",
			"ideas": [
				"Using my patriarchal blessing, I can discover the plans that my Savior has for me.",
				"Instead of drifting through life, I can create plans and goals that will give my life meaning, purpose, and direction.",
				"I can make plans to bless the lives of others, and I can work faithfully until I have done my part to help them.",
				"By counseling with my Father in Heaven, I can create those plans in my life that will bring me righteousness and joy.",
				"In all my planning, I can patiently include the timing of the Lord, realizing that ultimately it is *His* will and timing that must be fulfilled."
			]
		},
		{
			"attribute": "Powerful",
			"title": "I Can Receive Power from Heaven",
			"ideas": [
				"I can purify and cleanse my life by living like my Savior, so that I can receive heavenly power and strength.",
				"I can follow my Savior’s example to use the power that I have been given by Him to bless the lives of others.",
				"I can increase my righteousness so that the powers of heaven will not be obstructed in my life, thereby receiving greater power through my Savior’s mercy and grace.",
				"I can study the lives of the prophets and other good men and women to discover how they were given power in their callings. I can learn of the good things that *they* did with this heavenly power."
			]
		},
		{
			"attribute": "Prayerful",
			"title": "I Can Petition My Father",
			"ideas": [
				"I can make daily prayer an important and essential part of my life.",
				"I can learn to “pray always” by having my heart and thoughts directed towards God throughout my day.",
				"I can counsel with heaven and contemplate beforehand what things I should request in my prayers, so that my desires are righteous and so that God will be able to grant my requests.",
				"I can encourage those around me who are struggling in their lives to turn to Heavenly Father in prayer."
			]
		},
		{
			"attribute": "Preserved",
			"title": "I Can Seek for the Preservation of Heaven",
			"ideas": [
				"Through my faith, I can pray daily for the Lord’s preservation and help.",
				"I can live in daily gratitude for my life, health, and preservation with which the Lord has blessed me.",
				"I can open my eyes of faith and recognize the many times that I have been preserved in this hazardous world. I can thank my Savior for His merciful interventions on my behalf."
			]
		},
		{
			"attribute": "Pride-less",
			"title": "I Can Avoid Pride",
			"ideas": [
				"By meekly submitting my will to God, I can consciously avoid the pestilence of pride.",
				"On a regular basis, I can check my soul for prideful attitudes which I can then work on overcoming.",
				"I can seek for humility in my heart, deferring to the Lord and to His will.",
				"I can avoid pridefully comparing myself to others.",
				"I can freely forgive others.",
				"I can seek to put my Savior Jesus Christ *first* in my life."
			]
		},
		{
			"attribute": "Profound",
			"title": "I Can Become More Profound and Deep",
			"ideas": [
				"By turning to the Source of all truth, I can fill my mind and heart with the teachings of God.",
				"I can use the gift of discernment to determine if I am hearing truth or deception from others, and I can filter this information accordingly.",
				"Instead of wasting my time with shallow things, I can intentionally spend time studying the words of my Savior and His prophets, seeking for a deeper understanding of the truths of eternity.",
				"I can study and then pause to contemplate the teachings of my Savior, seeking deeper understanding of His mysteries and His many mighty works."
			]
		},
		{
			"attribute": "Protector",
			"title": "I Can Be a Protector",
			"ideas": [
				"I can protect my family from spiritual danger through regular family prayer, daily family scripture study, weekly family home evening, and frequent temple attendance.",
				"I can stand up to protect and defend those who are being ridiculed, mocked, or belittled.",
				"I can protect the good reputations and lives of others by speaking well of them and by avoiding gossip or criticism."
			]
		},
		{
			"attribute": "Provider",
			"title": "I Can Be a Provider",
			"ideas": [
				"I can provide for those who fall under my stewardship, giving them the necessities of life so that they can thrive and grow. I can bless them in their wants as the Lord guides me.",
				"I can donate generously to the fast offering fund so that I can help the Church provide for those who are in need of assistance.",
				"I can bless those around me who are in need, providing a kind word and a helpful hand.",
				"I can provide hope and spiritual food to those around me by sharing the glorious message of the restored gospel with my family and friends."
			]
		},
		{
			"attribute": "Pure",
			"title": "I Can Strive to Be More Pure",
			"ideas": [
				"By eliminating sources of filthiness in my life, I can become more pure.",
				"Through the sanctifying influence of the Holy Spirit, I can seek for changes in my life that will make me more pure.",
				"I can stop impure thoughts as they are first forming in my brain, nipping them in the bud so that they don’t grow in my mind.",
				"I can look at others as children of God and not as objects of personal gratification, allowing virtue to garnish my thoughts.",
				"Since the Holy Spirit does not dwell in dirty locations, I can clean up my home and environment so that I will feel His Spirit strongly."
			]
		},
		{
			"attribute": "Purifier",
			"title": "I Can Let My Savior Purify My Soul",
			"ideas": [
				"By following the Holy Spirit, I can repent and take those steps that are needed to cleanse and purify my soul through my Savior.",
				"In my life I can seek for purity and for obedience to Heavenly Father.",
				"I can humbly submit to the will of my Savior so that He can teach me those things I need to do to purify my soul.",
				"Having repented, I can seek for purity in all that I do."
			]
		},
		{
			"attribute": "Rejected",
			"title": "I Can Be Comforted When Rejected",
			"ideas": [
				"When I am rejected, I can rely upon my Savior for peace, comfort, and strength.",
				"I can pray for those who reject me, asking the Lord to bless them with understanding and kindness.",
				"I can avoid rejecting my Savior by humbly obeying Him in all that I do.",
				"I can stop the spreading plagues of hatred and rejection by loving and accepting all of my family and friends."
			]
		},
		{
			"attribute": "Reliable",
			"title": "Through Christ I Can Be More Reliable",
			"ideas": [
				"By making good choices and by doing what I have promised others I will do, I can be more reliable for my family and my friends.",
				"Instead of spreading negativity, I can be a source of peace by sharing the good news of the gospel and other messages of hope with my family and friends. This will help to strengthen their foundations of faith.",
				"I can focus on and rely more upon my Savior by faithfully following Him so that “I will not put my trust in the arm of flesh” (2 Nephi 4:34)."
			]
		},
		{
			"attribute": "Rememberer",
			"title": "I Can Remember My Savior",
			"ideas": [
				"By feasting on the words of the scriptures, I can remember all of the good things that my Savior has done for me.",
				"I can remember the Lord daily through my prayers and through scripture study.",
				"I can remember the Lord weekly through partaking of the sacrament and renewing my covenants with Him.",
				"I can remember the Lord often through regular temple attendance.",
				"I can remember the Lord by drinking deeply from the semi-annual messages of general conference.",
				"I can take time to ponder and reflect as I open my heart and mind to remember my Savior and receive personal revelation from Him."
			]
		},
		{
			"attribute": "Resolute",
			"title": "I Can Be Resolute",
			"ideas": [
				"I know that life can sometimes be difficult, but I can look for the positive things in my life with eyes of gratitude. I can thank my Heavenly Father for these blessings. By doing this, I can strengthen my resolve and avoid complaining.",
				"Instead of setting unrealistic expectations which are certain to disappoint me, I can recognize that people—and life in general—are never perfect. I can find satisfaction and peace in the here and now.",
				"With confidence in my Savior, I can choose to have a positive attitude in bad times and in good times."
			]
		},
		{
			"attribute": "Righteous",
			"title": "I Can Choose Righteousness",
			"ideas": [
				"Life is filled with many choices. I can consciously choose those things which will bring me eternal happiness and joy.",
				"When I am faced with temptations, I can turn to my Savior for His strength. He can help me overcome powerful addictions. He can transform me into a better person.",
				"I can remember that relying upon the Lord is not a sign of weakness; it is a sign of humility and strength. His righteousness can help me to become more righteous."
			]
		},
		{
			"attribute": "Sanctifier",
			"title": "I Can Be Sanctified Through My Savior",
			"ideas": [
				"I can yield my heart to my Savior so that He can purify and sanctify me.",
				"I can avoid sinful influences and seek for more purity in my life so that I can feel the sanctifying influence of my Savior and the Holy Spirit.",
				"I can submit my will to the Lord’s all-knowing love and guidance so that He can transform me into the person I need to become.",
				"I can be an influence for good to others, encouraging them to seek for the joyful sanctification to be found in the Lord."
			]
		},
		{
			"attribute": "Savior",
			"title": "I Can Save and Not Destroy",
			"ideas": [
				"Whenever I have the opportunity, I can save the honor and good reputation of those I know by speaking kindly about them.",
				"I can share the saving message of the gospel with those I know and love.",
				"I can encourage my sisters and brothers in their journey along the pathway of life, building them up and strengthening them so that they can find their own salvation and eternal life through Jesus Christ.",
				"I can be one of the “saviors [who] shall come up on mount Zion” (Obadiah 1:21) by attending the temple, and also by searching for my ancestors who need these saving ordinances."
			]
		},
		{
			"attribute": "Scriptorian",
			"title": "I Can Be a Scriptorian",
			"ideas": [
				"By making scripture study a daily part of my life, these sacred writings can become a part of who I am.",
				"I can seek for eternal truths in my studies so that my learning from the scriptures can be more meaningful.",
				"Instead of casually reading the scriptures, I can take time to search and ponder the word of God, applying it to any problems that I face.",
				"I can use a personal notebook to carefully record those things I learn from the scriptures and to also preserve those truths that I am taught by the Holy Spirit. By doing this, I will create a personal set of scriptures, just like the prophets of old did."
			]
		},
		{
			"attribute": "Servant",
			"title": "I Can Be a Quiet and Secret Servant",
			"ideas": [
				"I can seek to see others as my Savior sees them, and I can serve them with pure intentions, focusing on them and not on the gratitude (or even the lack of gratitude) that is given back to me.",
				"I can open my eyes to the many opportunities of service around me—a kind word, a helpful hand, an open and understanding heart.",
				"I can see my Savior in those whom I serve, knowing that I am actually serving Him when I help others."
			]
		},
		{
			"attribute": "Shepherd",
			"title": "I Can Be a Shepherd to Others",
			"ideas": [
				"I can shepherd and protect those within my stewardship, helping to shield them from spiritual harm and praying for their welfare.",
				"Through my righteous example, I can encourage others to stay on the gospel path, shepherding them as I demonstrate that no matter what trials I experience, I can still be filled with happiness and joy.",
				"As I strengthen the faith of those around me through my words and my actions, I will help to lead them to the Good Shepherd who loves all of us so deeply."
			]
		},
		{
			"attribute": "Sinless",
			"title": "I Can Avoid Sin",
			"ideas": [
				"I can avoid the entanglements of sin, staying clear of temptations, people, situations, or media that might cause me to succumb.",
				"Through the sanctifying and purifying power of my Savior, I can experience a “mighty change in [my] heart” (Alma 5:14) so that I will abhor sin and continually desire to remain pure.",
				"By seeking help from my Savior to overcome the natural man, I can teach my spirit how to control my body. This will enlighten my soul, bringing me to higher and higher levels of purity and righteousness."
			]
		},
		{
			"attribute": "Spiritual",
			"title": "I Can Be a More Spiritual Person",
			"ideas": [
				"By taking time to meditate and pray, I can make certain that I am connecting with the spiritual realm on a regular basis.",
				"I can seek for answers to my gospel questions, realizing that I may need to wait for the answers to arrive. I can be patient as I continue to open my heart to the wisdom that can be found through God.",
				"I can spend more time focusing on the things of eternity instead of the shallow things of mortality."
			]
		},
		{
			"attribute": "Standard",
			"title": "I Can Be an Example of Truth and Righteousness",
			"ideas": [
				"I can strive to be a standard to my family and friends—a pure conduit of truth—as I seek for accuracy and authenticity in all that I say and do.",
				"I can use the gift of discernment to find pure truth in this world which is filled with deception.",
				"By seeking to follow the Lord’s law and commandments, I can become a shining example of happiness for my family and friends.",
				"By relying upon my Savior, I can mirror His light, both in word and in deed, pointing others to Him."
			]
		},
		{
			"attribute": "Straightforward",
			"title": "I Can Be Straightforward and Uncomplicated",
			"ideas": [
				"When others ask me for my help or for my opinion, I can be straightforward and kind in answering their questions.",
				"When seeking for knowledge, I can search for the simple, essential truths of life.",
				"I can make my life less complicated by choosing carefully how I will spend my time, and by narrowing down the activities I will pursue.",
				"I can clean up, purify, and simplify my environment by de-junking and getting rid of unnecessary things which are complicating my life and eating up my time."
			]
		},
		{
			"attribute": "Strengthens",
			"title": "I Can Strengthen Others",
			"ideas": [
				"I can remain true to my covenants and true to my faith, thereby strengthening the faith of those around me.",
				"I know that the true Source of spiritual strength is my Savior Jesus Christ. I can make Him a foundational fortitude in my life and encourage others to rely upon Him as well.",
				"When I have questions about the gospel, I can turn to my Savior—the fountain of living waters—to find the answers I seek.",
				"As I seek for answers to my gospel questions, I can keep my doubts and questions to myself so that the faith of others will not be weakened. When I finally find the answers I have been seeking, I can share the strength of these truths with others, as the Lord permits me."
			]
		},
		{
			"attribute": "Submissive",
			"title": "I Can Be Submissive and Humble",
			"ideas": [
				"I can prepare myself to submit to the will of my Savior by remembering that He is the Source of true wisdom and His guidance is truly priceless.",
				"By listening closely to the Holy Spirit, I can seek the will of my Father in Heaven and my Savior more closely in my life.",
				"When facing temptation, I can sacrifice my pride and submit my will to God by making right choices."
			]
		},
		{
			"attribute": "Sufferer",
			"title": "I Can Find Solace in My Grief and Sorrow",
			"ideas": [
				"As I turn to my Savior—who has already experienced the pain, grief, and sorrow that I am feeling—I can find solace and peace.",
				"I can offer kind words and encouragement to those around me who are suffering, as I “mourn with those that mourn; yea, and comfort those that stand in need of comfort” (Mosiah 18:9).",
				"When I am going through trials, I can hold fast to my faith. The grief and sorrow that I might be experiencing today will not last forever. The sun will rise again someday and the dark night will be ended."
			]
		},
		{
			"attribute": "Sympathetic",
			"title": "I Can Be Sympathetic",
			"ideas": [
				"Sympathy is a learned skill, and I can become better at it each day by opening my eyes to the hopes, dreams, struggles, and successes of my brothers and sisters.",
				"As I allow the Holy Spirit to touch and soften my heart, I can become more sympathetic to those around me.",
				"As I learn to see others as my Savior sees them, I can sympathetically “walk in their shoes” and recognize the trials and struggles that they are experiencing."
			]
		},
		{
			"attribute": "Teacher",
			"title": "I Can Be a Teacher of Truth",
			"ideas": [
				"I can humbly seek for the principles of eternity so that I can enlighten others with what I have learned.",
				"By following the Spirit, I can know what things I should say to others and then share those things that each person is prepared to hear.",
				"I can open my mouth and spread the wonderful news of the restored gospel with everyone I know, teaching them of the plan of happiness.",
				"As I pray for the Spirit to be with me in my teaching, I can edify myself and others with principles that come from the Source of all truth—God."
			]
		},
		{
			"attribute": "Temperate",
			"title": "I Can Be Temperate",
			"ideas": [
				"When I have the opportunity to say something, I can mentally double check that I am being charitable, and I can say those things that would bless others and not injure them.",
				"As I try to arrive early to my appointments and fulfill my commitments without procrastination, I can avoid feeling rushed and harried in my life. This will also help me to avoid feeling stressed and angry.",
				"I can seek to improve those areas in my life in which I might be lacking self-control."
			]
		},
		{
			"attribute": "Tempted",
			"title": "I Can Rise Above Temptations",
			"ideas": [
				"I can use the power of prayer to rise above the temptations I face.",
				"I can avoid circumstances and people who might bring temptation into my life.",
				"Since thoughts lead to action, I can clear my mind of bad thoughts and stop temptations in my mind before they become actions that I regret.",
				"I can turn to my Savior for the power and the strength to avoid and resist temptation."
			]
		},
		{
			"attribute": "Trustworthy",
			"title": "I Can Trust, and I Can Be More Trustworthy",
			"ideas": [
				"I can trust that God has a plan for me, even if I don’t clearly see or fully understand His plan yet.",
				"I can trust my Savior more fully, allowing Him to guide me, to mold me, and to change my life to match His vision of who I can be.",
				"As I trust in my Savior, I can become a person whom others can trust.",
				"I can be true to my word and honor my commitments so that I can be more trustworthy to my family and friends."
			]
		},
		{
			"attribute": "Truthful",
			"title": "I Can Pursue Truth",
			"ideas": [
				"Instead of wasting my time learning things that are of little value or of no consequence, I can pursue the truths of eternity.",
				"When I hear or read something new, I can pause and use the gift of discernment to determine if it is true or not.",
				"I can study the scriptures and other sources of spiritual truth so that I can learn to recognize my Savior’s voice and follow it.",
				"I can be more honest with everyone in my life."
			]
		},
		{
			"attribute": "Undeterred",
			"title": "I Can Be Undeterred in Following Chris",
			"ideas": [
				"I can hold firmly to my faith in my Savior, for I know that no matter what befalls me, He will help me. When the dark night is ended, I will be blessed because of my faithfulness.",
				"My Savior Jesus Christ gives me hope and strength. I can rely on Him as I move forward in faith.",
				"I can strengthen and fortify my family and friends by being undeterred in my faith and by encouraging them to also remain faithful.",
				"I can ignore my critics or those who might taunt me for choosing the right. By focusing on my Savior, I can be undeterred like He was."
			]
		},
		{
			"attribute": "Unentitled",
			"title": "I Can Rise Above Injustice and Unfairness",
			"ideas": [
				"Like my Savior, I can freely forgive those who might treat me unfairly.",
				"Whenever I experience any injustice or unfairness, I can remember that these things are simply a part of mortality.",
				"I can let grievances go, both in my heart and in my mind, so that these problems will not fester and turn me into a self-appointed victim.",
				"I can seek to be fair and just to everyone, letting go of any feelings of entitlement."
			]
		},
		{
			"attribute": "Unifier",
			"title": "I Can Be a Unifier",
			"ideas": [
				"I can seek for the way of peace in dealing with others. I can “agree with thine adversary quickly, whiles thou art in the way with him” (Matthew 5:25).",
				"I can search for points of agreement when talking with others.",
				"I can avoid judging others so that I will not be biased against them. I can then bond with them in a spirit of unity and understanding.",
				"I can seek to understand others’ points of view, picturing what it would be like to walk in their shoes."
			]
		},
		{
			"attribute": "United",
			"title": "I Can Be United with God’s Will",
			"ideas": [
				"I can seek to align my will to the wills of my Father in Heaven and my Savior. I can be united with Them.",
				"By following the quiet promptings of the Holy Spirit, I can bend and change my desires so that they are more in harmony with God’s will.",
				"I can become more united with my Savior and my Father in Heaven as I seek to be as holy and pure as They are.",
				"As an *imperfect* child of God, I can submit my will and my life to my *perfect* Father in Heaven."
			]
		},
		{
			"attribute": "Unpretentious",
			"title": "I Can Be Unpretentious",
			"ideas": [
				"I can seek for honor from my Father in Heaven rather than the honor of the world.",
				"By quietly and humbly serving others, I can receive those heavenly rewards which are of greater value than fame and recognition from the world. The fame of the world is fickle and will eventually fade away.",
				"By looking at others through the lens of charity, I can see them as people to love and serve instead of seeing them as objects who might give me adoration or praise."
			]
		},
		{
			"attribute": "Unselfish",
			"title": "I Can Be Unselfish",
			"ideas": [
				"Like my Savior, I can unselfishly share with others those bounteous blessings which I have been given.",
				"I can share the priceless and precious truths of the gospel with my family and friends.",
				"I can share my time and attention, removing all digital distractions, so that I can focus on my family and friends.",
				"I can share the talents with which I have been blessed, bringing happiness and joy to others."
			]
		},
		{
			"attribute": "Visionary",
			"title": "I Can Be More Visionary",
			"ideas": [
				"By focusing on the eternal perspective of life, I can try to see the bigger picture in the events that happen to me.",
				"I can eliminate from my life any false precepts which might cloud my vision.",
				"By seeking truth and vision from my Savior, I can see more clearly those things that I should do.",
				"I can study my patriarchal blessing to find the Lord’s vision for me.",
				"By focusing on my Savior through prayer and scripture study, I can recognize and avoid distractions in my life which might lead me astray."
			]
		},
		{
			"attribute": "Wise",
			"title": "I Can Be Wise",
			"ideas": [
				"I can rely upon my Father in Heaven and my Savior Jesus Christ. Their wisdom will help me to be wise.",
				"I can seek for pure sources of truth which will fill me with heavenly wisdom.",
				"I can avoid the false teachings of the world by praying for the gift of discernment. Through this gift I can discern between truth and error, and I can fill my soul with *true* wisdom."
			]
		},
		{
			"attribute": "Witness",
			"title": "I Can Be a Witness",
			"ideas": [
				"I can seek to know my Savior Jesus Christ better by studying His words and His life so that I can stand as a better witness of Him.",
				"Through my good actions and kind words, I can witness of the reality of my Heavenly Father and His Son, Jesus Christ.",
				"I can open my mouth and share my testimony of Jesus Christ with those who are willing to listen."
			]
		},
		{
			"attribute": "Worker",
			"title": "I Can Be a Hard Worker",
			"ideas": [
				"I can organize my life so that I can more effectively do those things my Savior has asked me to do.",
				"I can learn to work hard—seeking for opportunities to stretch myself and grow, but also balancing my work with appropriate periods of rest.",
				"I can focus on the work of the Lord, laboring to bless the souls of my family, friends, and neighbors."
			]
		}
	]
};