import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";

import cover from "../images/cover.png"
import VPlogo from "../images/vplogo.png"

export default function Footer() {
  const mapLinks = (arr) => arr.map(([href, title]) => 
    <Link key={href} sx={{ color: "black", paddingBottom: "20px" }} variant="body2" underline="none" href={`/${href}`}>{title}</Link>
  );

  return (<Box sx={{ padding: "30px 20px 40px 40px" }}>
    <Grid container spacing={5}>
      <Grid item sm={3} md={2} lg={2} sx={{display: {xs: "none", sm: "block"}}}>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Paper elevation={24} sx={{ width: "135px", height: "200px" }}>
            <img src={cover} alt="book cover" width="135px" height="200px" />
          </Paper>
        </Box>
      </Grid>
      <Grid item sm={2} md={2} lg={2}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {mapLinks([["home", "Home"], ["goals", "My Goals"], ["aboutthebook", "About the Book"], ["profile", "My Profile"]])}
        </Box>
      </Grid>
      <Grid item sm={2} md={2} lg={2}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {mapLinks([["feedback", "Feedback"], ["help", "Help"], ["privacy", "Privacy Policy"]])}  
        </Box>
      </Grid>
      <Grid item sm={5} md={6} lg={6}>
        <Typography sx={{ paddingBottom: "10px" }} variant="body2">
          © {new Date(Date.now()).getFullYear()} by <Link underline="none" href="https://veritypublishing.com">Verity Publishing, Inc.</Link>
        </Typography>
        <Typography variant="body2">All rights reserved.</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", }}>
          <a href="https://veritypublishing.com">
            <img src={VPlogo} alt="Verity Publishing logo" width="107px" height="60px" style={{paddingTop: 10}} />
          </a>
        </Box>
      </Grid>
    </Grid>
  </Box>);
}