import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import logoGradient from "../images/dc-logo-gradient.png";
import beStillFg from "../images/be-still-fg.png";
import beStillBg from "../images/be-still-bg.jpg";
import hope from "../images/hope.png";
import heal from "../images/heal.png";
import ourRock from "../images/our-rock.png";
import accent from "../images/accent.png";

import "./home.scss";

function BeginButton({white}) {
  return <Link to="/goals">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.39 250" className={`beginButton${white ? " beginButtonWhite" : ""}`}>
      <circle cx="187.44" cy="113.5" r="85.87" className="beginButtonC1" />
      <circle cx="105.12" cy="180.2" r="42.17" className="beginButtonC2" />
      <circle cx="41.48" cy="58.52" r="14.4"   className="beginButtonC3" />
      <g className="beginButtonC1">
        <text style={{fill: white ? "#000" : "#fff", fontFamily: "EBGaramond-Regular, 'EB Garamond'", fontSize: "29.52px", fontVariationSettings: "'wght' 400", transform: "translate(126.43px, 95.53px)"}}>
          <tspan x="0" y="0">Begin </tspan>
          <tspan style={{letterSpacing: "-.1em"}} x="70.39" y="0">Y</tspan>
          <tspan x="84.5" y="0">our</tspan>
          <tspan x="17.22" y="35.42">Journey</tspan>
        </text>
        <path style={{fill: white ? "#000" : "#fff"}} d="M188.69,171.89l-1.33-1.36,7.83-7.83h-16.64v-1.9h16.64l-7.83-7.83,1.33-1.36,10.14,10.14-10.14,10.14Z" />
      </g>
    </svg>
  </Link>;
}

export default function Home() {
  return <>
    <Helmet title="Home"/>
    <Box>
      <Grid container spacing={2} sx={{padding: "20px", paddingTop: "5vw"}}>
        <Grid item xs={0} md={1}/>
        <Grid item xs={12} md={7}>
          <img src={logoGradient} alt="Discovering Christ logo" className="logoGradient"/>
          <h1 className="headliner">Striving to become more like our Savior Jesus Christ</h1>
          <BeginButton/>
        </Grid>
        <Grid item md={3}>
          <div className="beStill">
            <img src={beStillBg} alt="Be Still by Greg Olsen" className="beStillBg"/>
            <img src={beStillFg} alt="Be Still by Greg Olsen" className="beStillFg"/>
          </div>
        </Grid>
      </Grid>
    </Box>
    <Box>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <div className="descriptionWrapper">
        <div className="bokeh">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="descriptionContainer">
            <div className="main">
              <Typography variant="h4" sx={{color: "white"}}>No matter what problems we might be facing, our Savior Jesus Christ is the answer.</Typography>
              <p><span>He can bring us <img src={hope} alt="hope"/> when the night is dark.</span></p>
              <p><span>He can <img src={heal} alt="heal"/> our wounded souls.</span></p>
              <p><span>He is <img src={ourRock} alt="our rock"/> when the storms of life are battering us.</span></p>
              <img src={accent} alt="" height={20}/>
              <p><span>He wants us to become like Him. “I have given you an example, that ye should do as I have done” (John 13:15).</span></p>
              <p><span>As we become more like him, He will lift our burdens and give us hope.</span></p>
              <img src={accent} alt="" height={20}/>
              <p><span>With that end in mind, this website has been created to help in the quest to become more like our Savior. You can set goals and create reminders to help you in your journey to become more like our Savior Jesus Christ.</span></p>
              <p><span>Begin by choosing an attribute of our Savior to focus on, then set a goal to work on emulating this attribute. If you want, reminders about this goal can be sent to your cell phone or your email inbox.</span></p>
              <p><span>To get started, click the button below.</span></p>
              <BeginButton white/>
            </div>
          </div>
        </div>
      </Grid>
    </Box>
  </>;
}