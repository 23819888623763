import React, { useContext } from "react";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getDatabase, ref, remove, child, update } from "firebase/database"

import ReminderSwitch from "./ReminderSwitch";
import AttributeChip from "./AttributeChip";
import { CheckButton, DeleteButton, EditButton } from "./ActionButtons";
import {UserContext} from '../../Wrapper';
import { firebaseApp } from '../../index.js';

const columns = [
  { id: 'attribute', label: 'Attribute' },
  { id: 'goal', label: 'Goal' },
  { id: 'reminders', label: 'Reminders' },
  { id: 'actions', label: 'Actions' }
];

export default function GoalsList({goalList, reminderSwitchPressed, setAddingGoal, setEditGoal, setDialog}) {
  const db = getDatabase(firebaseApp);

  const user = useContext(UserContext);

  return goalList?.length > 0 ? <div className="goalsList">
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: "74vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(({id, label}) => (
                <TableCell key={id}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(goalList ?? [])
              .map(({key, data: {attribute, text, completeDate, reminder, reminderKey}, data}) => {
                return (
                  <TableRow hover role="checkbox" key={key}>
                    <TableCell>
                      <AttributeChip attribute={attribute}/>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{color: completeDate ? "gray" : null}}>{text}</Typography>
                      {completeDate && <i style={{color: "gray"}}>Completed on {new Date(completeDate).toLocaleDateString()}</i>}
                    </TableCell>
                    <TableCell>
                      {!completeDate && <>
                        <Stack direction="row" sx={{alignItems: "center"}}>
                          <ReminderSwitch icon="mail" name="Email" notifTypeAdded={user?.email} notifTypeUnsubbed={user?.unsubEmails} reminderNotifProp={reminder?.sendEmail} onChange={(e) => reminderSwitchPressed({type: "email", key, data, e})}/>
                          <ReminderSwitch icon="sms" name="Text" notifTypeAdded={user?.phone} notifTypeUnsubbed={user?.unsubTexts} reminderNotifProp={reminder?.sendText} onChange={(e) => reminderSwitchPressed({type: "text", key, data, e})}/>
                        </Stack>
                      </>}
                    </TableCell>
                    <TableCell>
                      <EditButton completeDate={completeDate} onClick={() => {
                        setAddingGoal(true);
                        setEditGoal({key, data});
                      }}/>
                      <DeleteButton onClick={() =>
                        setDialog({
                          title: "Delete this goal?",
                          message: "Are you sure that you want to delete this goal?",
                          buttonColor: "error",
                          onFinish: () => {
                            const {userId} = user || {};
                            if (reminderKey) {
                              remove(child(ref(db), `reminders/${reminderKey}`));
                            }
                            remove(child(ref(db), `users/${userId}/goals/${key}`));
                          }
                        })}/>
                      <CheckButton completeDate={completeDate} onClick={() => setDialog({
                        title: "Mark this goal as complete?",
                        message: "Do you want to mark this goal as completed? You will not receive anymore reminders about it.",
                        buttonColor: "success",
                        onFinish: async () => {
                          const {userId} = user || {};
                          try {
                            if (reminderKey) {
                              remove(child(ref(db), `reminders/${reminderKey}`));
                            }
                            await update(ref(db, `users/${userId}/goals/${key}`), { 
                              completeDate: Date.now(),
                              reminderKey: null,
                            });
                          }
                          catch (err) {
                            alert(`Failed to mark goal complete: ${err}`);
                          }
                        }
                      })}/>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </div> : null;
}