import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Grid, Link, Paper } from "@mui/material";

import logo from "../images/dc-logo-gradient.png"
import cover from "../images/cover.png"

export default function AboutTheBook() {
  return <>
    <Helmet title="About the book"/>
    <Grid container spacing={3} sx={{padding: "20px"}}>
      <Grid item xs={12} sx={{display: {xs: "flex", sm: "none"}, padding: "20px", justifyContent: "center"}}>
        <img src={cover} alt="book cover" style={{maxWidth: "50%", boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)"}}/>
      </Grid>
      <Grid item xs={12} sm={8}>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <img src={logo} alt="Discovering Christ" style={{ textAlign: "center", marginBottom: 0 }} height={70}/>
        </div>
        <h2 style={{ textAlign: "center", margin: "0 0 30px 0" }}>Developing the Attributes of our Savior Jesus Christ</h2>
        <p>No one is more important to us than our Savior Jesus Christ. He is the <i>only</i> means of our salvation.
           He is our everlasting example. How can we follow Him and grow to become more like Him?</p>
        <p>It’s impossible to become like someone whom we don’t truly understand. When pondering about our Savior,
           are we able to answer these vital questions:</p>
        <ul>
          <li>Who is He?</li>
          <li>What is He like?</li>
          <li>What are His fundamental attributes?</li>
          <li>What does He believe and what did He teach?</li>
          <li>How did He handle the adversity, trials, rejection, and temptations that He faced in His life?</li>
          <li>How can this knowledge help us in our own trials and temptations?</li>
        </ul>
        <p>We can turn to three sacred sources to discover who our Savior is: the scriptures, the teachings of
           the prophets, and the commandments He has given us. As we study His attributes from these sources,
           our understanding will expand, and our lives will improve significantly.</p>
        <p>Each chapter in this book focuses on one of our Savior’s attributes, concluding with powerful suggestions
           demonstrating how we can obtain these same qualities.</p>
        <p>As we apply His attributes in our lives, we will become more like Him. As we become more like Him,
           we will come to know Him better and we will be filled with power from heaven.</p>
        <p><i>Discovering Christ</i> can lead each of us to a richer life — a life filled with light, peace, and joy.</p>
        <p></p>
        <p>For more information about purchasing this book, please <Link href="https://www.veritypublishing.com">click here</Link>. </p>
      </Grid>
      <Grid item sm={4} sx={{display: {xs: "none", sm: "block"}, padding: "20px"}}>
        <img src={cover} alt="book cover" style={{width: "100%", boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)"}}/>
      </Grid>
    </Grid>
  </>;
}