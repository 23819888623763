import React from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";

export default function Privacy() {
  return <>
    <Helmet title="Privacy Notice"/>
    <Box sx={{padding: "20px"}}>
      <h1>Privacy Notice</h1>
      <h2>Our Commitment</h2>
      <p>We will not sell or distribute your information (i.e. your name, phone number or email address) to other third parties so that they can engage in unapproved marketing activies with you.</p>
      <p>We will contact you by email or text message to remind you of the goals that you have set, but only if you have enable reminders for that goal. We are only contacting you as you have directed us. In addition, if needed we might contact you by email about technical issues with the site.</p>
      <p>Your information is stored in a secure database. As a secondary precaution, we also encrypt the text of the goals that you create, so that if a malicious person were to somehow read the contents of our database, the text of the goals would be unreadable by their prying eyes.</p>
      <h2>Your Rights to Request Removal of Your Information</h2>
      <p>You have the right to contact us and request that all of your information be deleted from our database. Upon receiving this request, your information will be purged from our database within thirty (30) days of receiving the request.</p>
    </Box>
  </>;
}