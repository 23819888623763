import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import emailjs from "@emailjs/browser";
import { Box, Button, TextField, Typography } from "@mui/material";

import {UserContext} from '../Wrapper';

export default function Contact({feedback}) {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComment] = React.useState("");
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (dataLoaded || !user) return;

    // If we have it, fill in the user"s information for their name and email address
    const {name, email} = user;
    setName(name);
    setEmail(email);
    setDataLoaded(true);
  }, [user, dataLoaded]);

  const sendClick = async () => {
    try {
      await emailjs.send("service_fuv7o9r", "template_igrmpp9", {
        subject: `Discovering Christ ${feedback ? "Feedback" : "Help Assistance"}`,
        name: name,
        email: email,
        message: comment
      }, "cA24MCR_UVqjMA9G_");
      alert("Your message has been sent");
      navigate("/");
    }
    catch (err) {
      alert("An error occurred while sending: " + err);
    }
  }

  const title = feedback ? "Send Feedback" : "Help Assistance";
  return <>
    <Helmet title={title}/>
    <Box sx={{padding: "20px", display: "flex", flexDirection: "column"}}>
      <h1>{title}</h1>
      <Typography sx={{ paddingBottom: "10px" }}>
        {feedback ? "We would love to hear from you about any feedback that you have concerning this site." :
         "We would like to help you with any issues you might have experienced while using this site."}
      </Typography>
      <Typography sx={{ padding: "10px 0" }}>Please fill out the following information and we will get back with you.</Typography>
      <TextField sx={{ margin: "15px 0", width: "250px" }} label="Name" value={name} onChange={ (e) => setName(e.target.value) } />
      <TextField sx={{ margin: "15px 0", width: "250px" }} label="Email address" value={email} onChange={ (e) => setEmail(e.target.value) } />
      <TextField multiline rows="6" sx={{ margin: "15px 0" }} label="Comments" value={comment} onChange={ (e) => setComment(e.target.value) } />
      <Box sx={{ padding: "20px 10px", display: "flex", justifyContent: "end" }}>
        <Button sx={{ position: "absolute", left: "20px" }} variant="contained" disabled={comment === ""} onClick={sendClick}>Send</Button>
        <Button variant="outlined" onClick={() => navigate("/")}>Cancel</Button>
      </Box>
    </Box>
  </>;
}