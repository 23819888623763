import React from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";

export default function Error404() {
  return (<>
    <Helmet title="Page not found"/>
    <Box sx={{padding: "20px", width: "60vw", height: "40vh", backgroundColor: "#f0f4f8", position: "absolute", top: "100px", overflowY: "auto" }}>
      <h1>Page not found</h1>
      <p>We were unable to find the page you are seeking.</p>
      <p style={{ marginTop: "50px" }} ></p>
      <p>Although we are unable to help, you can always find the answers that you are seeking through our Savior Jesus Christ.</p>
      <p>He is the ultimate answer to all who seek Him.</p>
    </Box>
  </>);
}