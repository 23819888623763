import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Stack, Switch, TextField, Typography } from "@mui/material";
import { getDatabase, ref, update } from "firebase/database"

import { firebaseApp } from '../index.js'
import {UserContext} from '../Wrapper';
import Icon from "../components/icon.js";

function Field({label, val, setVal, error, setError}) {
  return <>
    <TextField error={Boolean(error)} sx={{ margin: "10px" }} label={label} value={val} onChange={(e) => {setVal(e.target.value); setError();}} />
    <Typography sx={{ margin: "0 10px 10px 10px" }} color="red" variant="caption">{error}</Typography>
  </>;
}

export default function Profile() {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState();
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState();
  const [unsubEmails, setUnsubEmails] = React.useState(false);
  const [showUnsubEmails, setShowUnsubEmails] = React.useState(false);
  const [unsubTexts, setUnsubTexts] = React.useState(false);
  const [showUnsubTexts, setShowUnsubTexts] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [showChangesSaved, setShowChangesSaved] = React.useState(false);
  const [dialog, setDialog] = React.useState();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (dataLoaded) return;

    // Load the profile info for the logged-in user
    const {userId, name, email, phone, unsubEmails, unsubTexts} = user || {};
    if (!userId) {
      return;
    }
    setName(name);
    setEmail(email);
    setPhone(phone);
    setUnsubEmails(unsubEmails || false);
    setShowUnsubEmails(email);
    setUnsubTexts(unsubTexts || false);
    setShowUnsubTexts(phone);
    setDataLoaded(true);
  }, [user, dataLoaded]);

  useEffect(() => {
    console.log({dataLoaded, user})
    if (dataLoaded && (user.unsubEmails !== unsubEmails || user.unsubTexts !== unsubTexts))
      saveButtonClick();
  }, [unsubEmails, unsubTexts]);

  const saveButtonClick = async () => {
    console.log("saving")
    setLoadingSave(true);
    // Get the logged-in userId
    const {userId} = user || {};
    // Validate the user's inputs
    const nameError = name === "",
      emailError = email !== "" && !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
      phoneError = phone !== "" && !phone.match(/^(\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    setNameError(nameError ? "Please enter your name" : null);
    setEmailError(emailError ? "Invalid email address" : email === "" && phone === "" ? "You must enter an email or phone number!" : null);
    setPhoneError(phoneError ? "Invalid phone number format" : email === "" && phone === "" ? "You must enter an email or phone number!" : null);
    if (nameError || emailError || phoneError || (email === "" && phone === "")) {
      setLoadingSave(false);
      return;
    }

    // Save the user's info to the database and to the session
    try {
      await update(ref(getDatabase(firebaseApp), `users/${userId}`), {
        name,
        email,
        phone, 
        unsubEmails,
        unsubTexts,
      });
      setLoadingSave(false);
      setShowChangesSaved(true);
      setShowUnsubEmails(email);
      setShowUnsubTexts(phone);
    }
    catch (err) { 
      console.log(`ERROR THROWN when saving: `, err);
      alert('An error occurred while saving: ' + err);
      setLoadingSave(false);
    }
  };

  return <>
    <Helmet title="Edit Profile"/>
    <Dialog open={Boolean(dialog)}>
      <DialogTitle>Unsubscribe from {dialog} messages?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to unsubscribe from all {dialog} messages? All goals that have a{dialog === "email" ? "n" : ""} {dialog} notification will be automatically opted out.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          dialog === "email" ? setUnsubEmails(true) : setUnsubTexts(true);
          setDialog();
        }} color="success">Yes, Unsubscribe Me</Button>
        <Button onClick={() => setDialog()} color="error" autoFocus>No, Cancel</Button>
      </DialogActions>
    </Dialog>
    <Box sx={{padding: "15px", backgroundColor: "#f0f4f8", minWidth: "250px",
              position: "absolute", top: 100, right: 50, overflowY: "auto", display: "flex", flexDirection: "column"}}>
      <h1 style={{ textAlign: "center", width: "100%" }}>Edit Profile</h1>
      {!dataLoaded && <CircularProgress/>}
      {dataLoaded && <><Field label="Name" val={name} setVal={setName} error={nameError} setError={setNameError}/>
      <Field label="Email" val={email} setVal={setEmail} error={emailError} setError={setEmailError}/>
      <Field label="Phone" val={phone} setVal={setPhone} error={phoneError} setError={setPhoneError}/>
      {showUnsubEmails && <FormControlLabel control={<Switch checked={unsubEmails} onChange={(e) => e.target.checked ? setDialog("email") : setUnsubEmails(false)}/>} label="Unsubscribe from all emails"/>}
      {showUnsubTexts && <FormControlLabel control={<Switch checked={unsubTexts} onChange={(e) => e.target.checked ? setDialog("text") : setUnsubTexts(false)}/>} label="Unsubscribe from all texts"/>}
      <Box sx={{ padding: "20px 10px", display: "flex", justifyContent: "end" }}>
        <Button 
          sx={{ position: "absolute", left: "25px" }} 
          variant="contained" 
          onClick={saveButtonClick}
          disabled={loadingSave}
        >
          {loadingSave && <CircularProgress size="1em" sx={{marginRight: 1}}/>}
          Save
        </Button>
        <Button variant="outlined" onClick={() => navigate('/goals')}>Cancel</Button>
      </Box>
      {showChangesSaved && <Stack direction="row" sx={{alignItems: "center"}}>
        <Icon color="green">done</Icon>
        Changes saved.
      </Stack>}</>}
    </Box>
  </>;
}