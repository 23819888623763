import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { initializeApp } from 'firebase/app';

import './index.scss';

import Wrapper from './Wrapper';
import AboutTheBook from './pages/aboutthebook';
import Contact from './pages/contact';
import Error404 from './pages/404';
import Goals from './pages/goals';
import Home from './pages/home';
import Logout from './pages/logout';
import Privacy from './pages/privacy';
import Profile from './pages/profile';
import Signin from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAQsuRRsDmBXzvD2weLlngWK1zHpgxq-Hc",
  authDomain: "discoveringchrist-f2453.firebaseapp.com",
  databaseURL: "https://discoveringchrist-f2453-default-rtdb.firebaseio.com",
  projectId: "discoveringchrist-f2453",
  storageBucket: "discoveringchrist-f2453.appspot.com",
  messagingSenderId: "13694885121",
  appId: "1:13694885121:web:b012cf4d31f87ff531cf00",
  measurementId: "G-2QLHM10LJY"
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: '#1a7abf',
      contrastText: '#f0f4f8',
    },
    secondary: {
      main: '#26438e',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: `"EB Garamond", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    fontSize: 24,
    button: {
      textTransform: "none",
    },
  }
});

const routes = [
  {
    path: "/",
    page: <Home/>,
    exact: true,
  },
  {
    path: "/goals",
    page: <Goals/>,
    exact: true,
    membersOnly: true,
  },
  {
    path: "/addgoal",
    page: <Goals addGoal={true} />,
    exact: true,
    membersOnly: true,
  },
  {
    path: "/signin",
    page: <Signin />,
    exact: true,
  },  
  {
    path: "/forgot-password",
    page: <ForgotPassword />,
    exact: true,
  }, 
  {
    path: "/reset-password",
    page: <ResetPassword />,
    exact: true,
  },
  {
    path: "/signup",
    page: <Signin signup={true} />,
    exact: true,
  },
  {
    path: "/logout",
    page: <Logout />,
    exact: true,
  },
  {
    path: "/privacy",
    page: <Privacy />,
    exact: true,
  },
  {
    path: "/profile",
    page: <Profile />,
    exact: true,
    membersOnly: true,
  },
  {
    path: "/help",
    page: <Contact />,
    exact: true,
  },
  {
    path: "/feedback",
    page: <Contact feedback={true} />,
    exact: true,
  },
  {
    path: "/aboutthebook",
    page: <AboutTheBook />,
    exact: true,
  },
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Helmet titleTemplate="%s - Discovering Christ" defaultTitle="Discovering Christ"/>
        <BrowserRouter>
          <Routes>
            {routes.map(({exact, path, page, membersOnly}) => <Route key={path} exact={exact} path={path} element={<Wrapper page={page} membersOnly={membersOnly}/>}/>)}
            <Route path="*" element={<Wrapper page={<Error404/>}/>}/>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);