
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { get, getDatabase, ref } from 'firebase/database';

import { firebaseApp } from '.';
import Header from './components/header';
import Footer from './components/footer';

export const UserContext = createContext();

export default function Wrapper ({page, membersOnly}) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(getAuth(firebaseApp), (user) => {
      if (!user?.uid && membersOnly) {
        if (window.location.pathname !== "/signin") {
          window.sessionStorage.setItem("loginDestination", window.location.pathname + window.location.search);
        }
        return navigate(`/signin`, {replace: true});
      }
      getUser(user?.uid);
    });
  }, [membersOnly, navigate]);

  const getUser = async (uid) => {
    const user = (await get(ref(getDatabase(firebaseApp), `users/${uid}`)))?.val() || {};
    setUser({
      ...user,
      userId: uid,
    });
  };

  return <UserContext.Provider value={user}>
    <Header/>
      <div className="headerBackground">
        {page}
      </div>
    <Footer/>
  </UserContext.Provider>;
};