import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAuth, sendPasswordResetEmail} from "firebase/auth";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Stack, TextField, Typography } from "@mui/material";

import "./signin.scss";
import { firebaseApp } from "..";

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);
   
  const sendClick = async () => {
    try {
      setError();
      setLoading(true);
      if (!email) {
        setError("Please enter your email.");
        return setLoading(false);
      }
      const result = await sendPasswordResetEmail(auth, email);
      console.log(result);
      setLoading(false);
      setSuccess(true);
    }
    catch (err) {
      console.log(`ERROR: `, err);
      setLoading(false);
      if (err?.code === "auth/invalid-email") {
        return setError("Please enter a valid email.");
      }
      if (err?.code === "auth/user-not-found") {
        return setError("An account with that email does not exist.");
      }
      setError("Unable to send your Forgot Password email. Please try again.");
    }
  };

  return <>
    <Helmet title={"Forgot Password"} /> 
    <Dialog open={success} onClose={() => navigate("/signin")}>
      <DialogTitle>
        Forgot Password email sent successfully
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          A Forgot Password email was successfully sent to {email}. Please check your inbox and spam folder, and follow the link in the email to reset your email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button href="/signin">
          Back
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container>
      <Grid item xs={0}  sm={2} md={3}/>
      <Grid item xs={12} sm={8} md={6}>
        <Paper className="signinPaper">
          <Box className="upperBox">
            <h1>Forgot Password</h1>
          </Box>
          <Stack className="mainBox" spacing={2}>
            <Typography>Enter your email below, then click "Send Forgot Password Email".</Typography>
            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Typography sx={{ color: "red" }}>{error}</Typography>
            <Button variant="contained" onClick={sendClick} disabled={Boolean(loading)}>{loading && <CircularProgress size="1em" sx={{marginRight: 1}}/>}Send Forgot Password Email</Button>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  </>;
}