import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseApp } from "../index.js"

// Logout the user and re-direct to the home page
export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    const logout = async () => {
      try {
        getAuth(firebaseApp).signOut();
      }
      catch (err) {
        alert("Failed to sign you out: " + err);
      }
      navigate("/");
    };
    logout();    
  });

  return <></>;
}

