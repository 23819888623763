import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { Box, Button, CircularProgress, Grid, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from "@mui/material";

import "./signin.scss";
import { firebaseApp } from "..";

export default function ResetPassword() {
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [params, setPageParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);
   
  const resetClick = async () => {
    try {
      setError();
      setLoading(true);
      if (!password) {
        setError("Please enter a password.");
        return setLoading(false);
      }
      if (!password2) {
        setError("Please repeat your password.");
        return setLoading(false);
      }
      if (password !== password2) {
        setError("Passwords do not match.");
        return setLoading(false);
      }
      console.log("code: ", params.get("oobCode"))
      const result = await confirmPasswordReset(auth, params.get("oobCode"), password);
      console.log(result);
      setSuccess(true);
      setLoading(false);
    }
    catch (err) {
      console.log(`ERROR: `, err);
      setLoading(false);
      setSuccess(false);
      if (err?.code === "auth/weak-password") {
        return setError("Passwords must be at least 6 characters long.");
      }
      if (err?.code === "auth/invalid-action-code") {
        return setError("This request is invalid. Go back and try again.");
      }
      setError("Unable to reset your password. Please try again.");
    }
  };

  return <>
    <Helmet title={"Reset Password"} /> 
    <Dialog open={success} onClose={() => navigate("/signin")}>
      <DialogTitle>
        Reset Password successfully
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your password has been reset successfully. Please sign in.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button href="/signin">
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container>
      <Grid item xs={0}  sm={2} md={3}/>
      <Grid item xs={12} sm={8} md={6}>
        <Paper className="signinPaper">
          <Box className="upperBox">
            <h1>Reset Password</h1>
          </Box>
          <Stack className="mainBox" spacing={2}>
            <Typography>Enter your new password below, and click "Reset Password" when you're finished.</Typography>
            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <TextField label="Repeat Password" type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
            <Typography sx={{ color: "red" }}>{error}</Typography>
            <Button variant="contained" onClick={resetClick} disabled={Boolean(loading)}>{loading && <CircularProgress size="1em" sx={{marginRight: 1}}/>}Reset Password</Button>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  </>;
}