import { IconButton } from "@mui/material";
import Icon from "../../components/icon";

export function EditButton({completeDate, onClick}) {
  return !completeDate ? <IconButton sx={{minWidth: "0px"}} onClick={onClick}>
    <Icon color={null}>edit</Icon>
  </IconButton> : null;
}

export function DeleteButton({onClick}) {
  return <IconButton sx={{minWidth: "0px"}} onClick={onClick}>
    <Icon color={"red"}>delete</Icon>
  </IconButton>
}

export function CheckButton({completeDate, onClick}) {
  return !completeDate ? <IconButton sx={{ fontSize: "0.8em" }} onClick={onClick}>
    <Icon color={"green"}>check_box</Icon>
  </IconButton> : null;
}